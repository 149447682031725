import * as React from "react";
import useAutocomplete, {
	AutocompleteGetTagProps,
} from "@mui/material/useAutocomplete";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import { autocompleteClasses } from "@mui/material/Autocomplete";
import { Modal } from "src/components/ModalComp";
import { HighlightOffOutlined } from "@mui/icons-material";

const Root = styled("div")(
	({ theme }) => `
  color: ${
		theme.palette.mode === "dark" ? "rgba(255,255,255,0.65)" : "rgba(0,0,0,.85)"
	};
  font-size: 14px;
`
);

const InputWrapper = styled("div")(
	({ theme }) => `
  width: 100%;
  height: 100%;
  border: 2px solid #002E66;
  background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
  border-radius: 4px;
  padding: 3px;
  display: flex;
 flex-wrap: wrap;
 overflowY:auto,

  &:hover {
    border-color: ${theme.palette.mode === "dark" ? "#177ddc" : "#40a9ff"};
  }

  &.focused {
    border-color: ${theme.palette.mode === "dark" ? "#177ddc" : "#40a9ff"};
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
overflow:auto;
  & input {
    background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
    color: ${
			theme.palette.mode === "dark"
				? "rgba(255,255,255,0.65)"
				: "rgba(0,0,0,.85)"
		};
    height: 30px;
    box-sizing: border-box;
    padding: 4px 6px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
  }
`
);

interface TagProps extends ReturnType<AutocompleteGetTagProps> {
	label: string;
}

function Tag(props: TagProps) {
	const { label, onDelete, ...other } = props;
	return (
		<div {...other}>
			<span>{label}</span>
			<CloseIcon onClick={onDelete} />
		</div>
	);
}

const StyledTag = styled(Tag)<TagProps>(
	({ theme }) => `
  display: flex;
  align-items: center;
  height: 24px;
  margin: 2px;
 
  background-color: ${
		theme.palette.mode === "dark" ? "rgba(255,255,255,0.08)" : "#fafafa"
	};

  box-sizing: content-box;

  outline: 0;
  overflow: hidden;


  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    font-size: 16px;
    cursor: pointer;
    padding: 4px;
  }
`
);

const Listbox = styled("ul")(
	({ theme }) => `
  width: 300px;
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
  overflow: auto;
  max-height: 250px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;

  & li {
    padding: 5px 12px;
    display: flex;

    & span {
      flex-grow: 1;
    }

    & svg {
      color: transparent;
    }
  }

  & li[aria-selected='true'] {
    background-color: ${theme.palette.mode === "dark" ? "#2b2b2b" : "#fafafa"};
    font-weight: 600;

    & svg {
      color: #1890ff;
    }
  }

  & li.${autocompleteClasses.focused} {
    background-color: ${theme.palette.mode === "dark" ? "#003b57" : "#e6f7ff"};
    cursor: pointer;

    & svg {
      color: currentColor;
    }
  }
`
);

export default function CustomizedHook({ data, onSelect }: any) {
	console.log(data, "=======");
	const {
		getRootProps,
		getInputLabelProps,
		getInputProps,
		getTagProps,
		getListboxProps,
		getOptionProps,
		groupedOptions,
		value,
		focused,
		setAnchorEl,
	} = useAutocomplete({
		id: "customized-hook-demo",
		defaultValue: [data[1]],
		multiple: true,
		options: data,
		getOptionLabel: (option: any) => option?.name,
		onChange: (_, selectedItem) => onSelect(selectedItem),
	});
	// console.log(data, "=======>");
	return (
		<Root>
			<div className="w-full h-32 overflow-y-auto my-4" {...getRootProps()}>
				{/* <Label {...getInputLabelProps()}>Customized hook</Label> */}
				<InputWrapper ref={setAnchorEl} className={focused ? "focused" : ""}>
					{value.map((option: any, index: number) => (
						<StyledTag label={option.name} {...getTagProps({ index })} />
					))}
					<input {...getInputProps()} />
				</InputWrapper>
			</div>
			{groupedOptions.length > 0 ? (
				<Listbox {...getListboxProps()}>
					{(groupedOptions as typeof data).map((option: any, index: any) => (
						<li {...getOptionProps({ option, index })}>
							<span>{option.name}</span>
							<CheckIcon fontSize="small" />
						</li>
					))}
				</Listbox>
			) : null}
		</Root>
	);
}

export const NotificationPreview = ({ data, setShowModal }: any) => {
	return (
		<Modal>
			<div className="absolute w-full h-full right-0 top-0 bg-[rgba(0,0,0,0.5)] flex justify-center items-center">
				<div className="w-[70%] mx-auto flex flex-col justify-center rounded-[20px] pb-10 bg-white">
					<div className="w-full px-10 pt-2 pb-2 mt-2 font-bold text-xl text-[#002E66] flex justify-between items-center">
						<h1>Notification Preview</h1>
						<button onClick={() => setShowModal(false)} disabled={false}>
							<HighlightOffOutlined
								fontSize="large"
								className="text-black cursor-pointer"
							/>
						</button>
					</div>
					<div>
						<hr />
					</div>

					<div className="px-4 py-4 ml-6">
						<div>
							<h5>{data?.title}</h5>
							<p className="text-sm mt-3">{data?.message}</p>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
};
