import { useFormik } from "formik";
import { useEffect } from "react";
import { Button } from "src/components/Button";
import { FormInput } from "src/components/inputs";
import { UpdateHQAdminInfoValidation } from "src/helpers/YupValidation";
import { UpdateHQAdminType } from "src/helpers/alias";
import {
	SuccessNotification,
	handleNotification,
} from "src/helpers/helperFunction";
import { useUpdateHqAdminProfileMutation } from "src/hq-admin/hq-api/settingsApiSlice";

export const ResetAdminInfo = (props: { close: () => void; data: any }) => {
	const [updateAdmin, addNewResult] = useUpdateHqAdminProfileMutation();

	async function addNewAdmin(values: any) {
		try {
			const response = await updateAdmin(values).unwrap();

			SuccessNotification(response?.status);
		} catch (error: any) {
			handleNotification(error);
		}
		props.close();
	}

	const Formik = useFormik<UpdateHQAdminType>({
		initialValues: {
			firstName: "",
			lastName: "",
			// email: "",
			phoneNumber: "",
			role: "hq_admin",

			id: props.data?.id,
			accountStatus: {
				status: "confirmed",
			},
		},
		validateOnBlur: true,
		validateOnChange: true,
		validationSchema: UpdateHQAdminInfoValidation,
		onSubmit: (...values: any) => {
			addNewAdmin({
				firstName: values?.firstName,
				lastName: values?.lastName,
				phoneNumber: values?.phoneNumber,
			});
		},
	});
	const styles =
		"h-[38px] py-6 rounded-[38px] w-full border border-gray-300 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-500 px-4 text-[14px] bg-[#D9D9D9]";
	const labelStyles =
		"block mb-[6px] text-black text-start font-normal text-[14px] text-black ml-5 my-6";

	const FormData = [
		{
			id: "firstName",
			name: "Admin's firstname",
			type: "text",
			styles: `${styles} ${
				Formik.errors.firstName && Formik.touched.firstName
					? "border-red-500"
					: "border-gray-300"
			}`,
			labelStyles: labelStyles,
			onChange: Formik.handleChange,
			value: Formik.values.firstName,
			onBlur: Formik.handleBlur,
			disabled: addNewResult?.isLoading,
			error: Formik.errors.firstName,
			touched: Formik.touched.firstName,
		},
		{
			id: "lastName",
			name: "Admin's lastname",
			type: "text",
			styles: `${styles} ${
				Formik.errors.lastName && Formik.touched.lastName
					? "border-red-500"
					: "border-gray-300"
			}`,
			labelStyles: labelStyles,
			onChange: Formik.handleChange,
			value: Formik.values.lastName,
			onBlur: Formik.handleBlur,
			disabled: addNewResult?.isLoading,
			error: Formik.errors.lastName,
			touched: Formik.touched.lastName,
		},
		// {
		// 	id: "email",
		// 	name: "Admin's email",
		// 	type: "email",
		// 	styles: `${styles} ${
		// 		Formik.errors.email && Formik.touched.email
		// 			? "border-red-500"
		// 			: "border-gray-300"
		// 	}`,
		// 	labelStyles: labelStyles,
		// 	onChange: Formik.handleChange,
		// 	value: Formik.values.email,
		// 	onBlur: Formik.handleBlur,

		// 	disabled: addNewResult.isLoading,
		// 	error: Formik.errors.email,
		// 	touched: Formik.touched.email,
		// },
		{
			id: "phoneNumber",
			name: "Admin's phone number",
			type: "text",
			styles: `${styles} ${
				Formik.errors.phoneNumber && Formik.touched.phoneNumber
					? "border-red-500"
					: "border-gray-300"
			}`,
			labelStyles: labelStyles,
			onChange: Formik.handleChange,
			value: Formik.values.phoneNumber,
			onBlur: Formik.handleBlur,
			disabled: addNewResult?.isLoading,
			error: Formik.errors.phoneNumber,
			touched: Formik.touched.phoneNumber,
		},
	];

	// HANDLE IMAGE UPLOAD TO BASE 64
	// async function uploadAvatar(e: { [index: string]: string | any }) {
	// 	Formik.setFieldValue("avatar", await convert2base64(e.target.files[0]));
	// }

	// Populate input with previous data
	useEffect(() => {
		if (!props.data) {
			return;
		}

		Formik.setValues({
			...props.data.profile,
			// avatar: props.data.avatar,
			id: props.data.id,
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props]);

	return (
		<form
			onSubmit={Formik.handleSubmit}
			className="w-full flex flex-col justify-center items-center px-4 h-full overflow-y-auto">
			<div className="grid grid-cols-1 w-full gap-x-2 content-center">
				{FormData.slice(0, 4).map((_v, i) => (
					<FormInput
						width="w-full"
						id={_v.id}
						name={_v.name}
						type={"text"}
						styles={_v.styles}
						labelStyles={_v.labelStyles}
						onChange={_v.onChange}
						value={_v.value}
						onBlur={_v.onBlur}
						disabled={_v.disabled}
						// error={_v.error}
						// touched={_v.touched}
					/>
				))}
			</div>

			{/* <div className="grid grid-cols-1 w-full gap-x-2 content-center pt-4">
				{Formik.values.avatar ? (
					<div>
						<Image
							image={Formik.values.avatar || ""}
							width={100}
							height={100}
							styles="mx-auto object-fit"
						/>
					</div>
				) : null}

				<div className="w-full h-24 mt-4">
					<Upload
						text="Upload profile image"
						name="avatar"
						onChange={(e: ChangeEvent<HTMLInputElement>) => {
							uploadAvatar(e);
						}}
					/>
				</div>
			</div> */}

			<div className="w-full">
				<Button
					text="Submit"
					disabled={addNewResult?.isLoading}
					showModal={addNewResult?.isLoading}
					className="h-[41px] mt-6 font-bold text-white rounded-[38px] w-full hover: bg-[#002E66]"
					type="submit"
				/>
			</div>
		</form>
	);
};
