import { useMemo } from "react";
import {
	registerdepots,
	selectCurrentLoginToken,
	selectCurrentLoginUser,
} from "src/features/auth/authSlice";
import { useAppSelector } from "./reduxhooks";

export const useAuth = () => {
	const user = useAppSelector(selectCurrentLoginUser);

	return useMemo(() => ({ user }), [user]);
};

export const useToken = () => {
	const token = useAppSelector(selectCurrentLoginToken);

	return useMemo(() => ({ token }), [token]);
};

export const useRegisteredDepot = () => {
	const listOfRegisteredDepot = useAppSelector(registerdepots);

	return useMemo(() => ({ listOfRegisteredDepot }), [listOfRegisteredDepot]);
};