export const Home = () => (
	<svg
		width="23"
		height="22"
		viewBox="0 0 23 22"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M11.0137 16.9945V13.9904M8.02964 1.82371L2.63224 6.02948C1.731 6.73044 1 8.22248 1 9.35404V16.7742C1 19.0974 2.89259 21 5.21578 21H16.8117C19.1349 21 21.0275 19.0974 21.0275 16.7842V9.49423C21.0275 8.28257 20.2163 6.73044 19.225 6.03949L13.0365 1.70355C11.6346 0.722204 9.38149 0.772272 8.02964 1.82371Z"
			stroke="white"
			stroke-width="2"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
);

export const Indicator = () => (
	<svg
		width="5"
		height="25"
		viewBox="0 0 5 25"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M0 0C2.76142 0 5 2.23858 5 5V20C5 22.7614 2.76142 25 0 25V0Z"
			fill="white"
		/>
	</svg>
);

export const Branch = () => (
	<svg
		width="25"
		height="22"
		viewBox="0 0 25 22"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M1 20.9895H24.1579M20.7684 21V16.3053M2.15789 20.9895V4.17895C2.15789 2.06316 3.21053 1 5.30526 1H11.8632C13.9579 1 15 2.06316 15 4.17895V20.9895M6.05263 6.51579H11.2632M6.05263 10.4632H11.2632M8.63158 20.9895V17.0421M20.7895 9.29474C19.5053 9.29474 18.4737 10.3263 18.4737 11.6105V14C18.4737 15.2842 19.5053 16.3158 20.7895 16.3158C22.0737 16.3158 23.1053 15.2842 23.1053 14V11.6105C23.1053 10.3263 22.0737 9.29474 20.7895 9.29474Z"
			stroke="white"
			stroke-miterlimit="10"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
);
export const Help = () => (
	<svg
		width="22"
		height="22"
		viewBox="0 0 22 22"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M8 21H14C19 21 21 19 21 14V8C21 3 19 1 14 1H8C3 1 1 3 1 8V14C1 19 3 21 8 21Z"
			stroke="white"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M1 11.7L7 11.68C7.75 11.68 8.59 12.25 8.87 12.95L10.01 15.83C10.27 16.48 10.68 16.48 10.94 15.83L13.23 10.02C13.45 9.45999 13.86 9.43999 14.14 9.96999L15.18 11.94C15.49 12.53 16.29 13.01 16.95 13.01H21.01"
			stroke="white"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
);
export const Notification = () => (
	<svg
		width="22"
		height="22"
		viewBox="0 0 22 22"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M20.9402 8.97607V11.9671C20.9402 15.9551 18.9462 17.9492 14.9581 17.9492H14.4596C14.1505 17.9492 13.8514 18.0987 13.662 18.348L12.1665 20.342C11.5085 21.2193 10.4317 21.2193 9.77368 20.342L8.27817 18.348C8.11864 18.1286 7.74975 17.9492 7.48056 17.9492H6.98205C2.99402 17.9492 1 16.9521 1 11.9671V6.98205C1 2.99402 2.99402 1 6.98205 1H12.9641"
			stroke="white"
			stroke-miterlimit="10"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M18.4476 5.98504C19.1087 5.98504 19.7426 5.72244 20.2101 5.255C20.6775 4.78756 20.9401 4.15358 20.9401 3.49252C20.9401 2.83146 20.6775 2.19748 20.2101 1.73004C19.7426 1.2626 19.1087 1 18.4476 1C17.7865 1 17.1526 1.2626 16.6851 1.73004C16.2177 2.19748 15.9551 2.83146 15.9551 3.49252C15.9551 4.15358 16.2177 4.78756 16.6851 5.255C17.1526 5.72244 17.7865 5.98504 18.4476 5.98504Z"
			stroke="white"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M14.9541 9.97308H14.9641M10.9651 9.97308H10.9751M6.97705 9.97308H6.98503"
			stroke="white"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
);
export const Settings = () => (
	<svg
		width="22"
		height="21"
		viewBox="0 0 22 21"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M1 11.3189V9.5589C1 8.5189 1.85 7.6589 2.9 7.6589C4.71 7.6589 5.45 6.3789 4.54 4.8089C4.02 3.9089 4.33 2.7389 5.24 2.2189L6.97 1.2289C7.76 0.758901 8.78 1.0389 9.25 1.8289L9.36 2.0189C10.26 3.5889 11.74 3.5889 12.65 2.0189L12.76 1.8289C13.23 1.0389 14.25 0.758901 15.04 1.2289L16.77 2.2189C17.68 2.7389 17.99 3.9089 17.47 4.8089C16.56 6.3789 17.3 7.6589 19.11 7.6589C20.15 7.6589 21.01 8.5089 21.01 9.5589V11.3189C21.01 12.3589 20.16 13.2189 19.11 13.2189C17.3 13.2189 16.56 14.4989 17.47 16.0689C17.99 16.9789 17.68 18.1389 16.77 18.6589L15.04 19.6489C14.25 20.1189 13.23 19.8389 12.76 19.0489L12.65 18.8589C11.75 17.2889 10.27 17.2889 9.36 18.8589L9.25 19.0489C8.78 19.8389 7.76 20.1189 6.97 19.6489L5.24 18.6589C4.8041 18.4079 4.48558 17.9942 4.35435 17.5087C4.22311 17.0231 4.28988 16.5053 4.54 16.0689C5.45 14.4989 4.71 13.2189 2.9 13.2189C1.85 13.2189 1 12.3589 1 11.3189Z"
			stroke="white"
			stroke-miterlimit="10"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
);
export const Support = () => (
	<svg
		width="22"
		height="22"
		viewBox="0 0 22 22"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M4.4794 17.5226V14.5879C4.4794 13.6131 5.24322 12.7387 6.32864 12.7387C7.30352 12.7387 8.17789 13.5025 8.17789 14.5879V17.4121C8.17789 19.3719 6.54975 21 4.58995 21C2.63015 21 1.00201 19.3618 1.00201 17.4121V11.2211C0.891458 5.57286 5.35377 1 11.002 1C16.6503 1 21.1025 5.57286 21.1025 11.1106V17.3015C21.1025 19.2613 19.4744 20.8894 17.5146 20.8894C15.5548 20.8894 13.9266 19.2613 13.9266 17.3015V14.4774C13.9266 13.5025 14.6905 12.6281 15.7759 12.6281C16.7508 12.6281 17.6251 13.392 17.6251 14.4774V17.5226"
			stroke="white"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
);

export const Transactions = () => (
	<svg
		width="19"
		height="22"
		viewBox="0 0 19 22"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M6.76128 8.999H12.2606M4.2396 18.6978C5.05949 17.8179 6.30934 17.8879 7.02925 18.8478L8.03912 20.1976C8.84902 21.2675 10.1589 21.2675 10.9688 20.1976L11.9786 18.8478C12.6985 17.8879 13.9484 17.8179 14.7683 18.6978C16.5481 20.5976 17.9979 19.9676 17.9979 17.308V6.03937C17.9979 2.00987 17.058 1 13.2785 1H5.71941C1.93988 1 1 2.00987 1 6.03937V17.298C1.01 19.9676 2.46982 20.5876 4.2396 18.6978Z"
			stroke="white"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
);

export const SearchIcon = () => (
	<svg
		width="22"
		height="22"
		viewBox="0 0 22 22"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M21 21L19 19M10.5 20C11.7476 20 12.9829 19.7543 14.1355 19.2769C15.2881 18.7994 16.3354 18.0997 17.2175 17.2175C18.0997 16.3354 18.7994 15.2881 19.2769 14.1355C19.7543 12.9829 20 11.7476 20 10.5C20 9.25244 19.7543 8.0171 19.2769 6.86451C18.7994 5.71191 18.0997 4.66464 17.2175 3.78249C16.3354 2.90033 15.2881 2.20056 14.1355 1.72314C12.9829 1.24572 11.7476 1 10.5 1C7.98044 1 5.56408 2.00089 3.78249 3.78249C2.00089 5.56408 1 7.98044 1 10.5C1 13.0196 2.00089 15.4359 3.78249 17.2175C5.56408 18.9991 7.98044 20 10.5 20Z"
			stroke="black"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
);

export const AddIcon = () => (
	<svg
		width="22"
		height="22"
		viewBox="0 0 22 22"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M7 11H15M11 15V7M11 21C16.5 21 21 16.5 21 11C21 5.5 16.5 1 11 1C5.5 1 1 5.5 1 11C1 16.5 5.5 21 11 21Z"
			stroke="white"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
);

export const HQIcon = () => (
	<svg
		width="22"
		height="22"
		viewBox="0 0 22 22"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M1 21H21M6 15.5H9M13 15.5H16M6 11H9M13 11H16M6 6.5H9M13 6.5H16M16 1H6C3 1 2 2.79 2 5V21H20V5C20 2.79 19 1 16 1Z"
			stroke="white"
			stroke-width="2"
			stroke-miterlimit="10"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
);

export const Lines = () => (
	<svg
		width="511"
		height="1"
		viewBox="0 0 511 1"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<line
			x1="4.37114e-08"
			y1="0.5"
			x2="511"
			y2="0.500045"
			stroke="black"
			stroke-opacity="0.5"
			stroke-dasharray="5 5"
		/>
	</svg>
);

export const User = () => (
	<svg
		width="30"
		height="30"
		viewBox="0 0 30 30"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			opacity="0.4"
			d="M15 30C23.2845 30 30 23.2845 30 15C30 6.7155 23.2845 0 15 0C6.7155 0 0 6.7155 0 15C0 23.2845 6.7155 30 15 30Z"
			fill="#06FF00"
		/>
	</svg>
);

export const Attendant = () => (
	<svg
		width="32"
		height="30"
		viewBox="0 0 32 30"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			opacity="0.4"
			d="M3.25293 28.9157V4.33735C3.25293 1.44578 5.19028 0 7.59028 0H19.1565C21.5565 0 23.4939 1.44578 23.4939 4.33735V28.9157H3.25293Z"
			fill="#06FF00"
		/>
		<path
			d="M25.6627 30.0001H1.08434C0.491566 30.0001 0 29.5085 0 28.9157C0 28.3229 0.491566 27.8314 1.08434 27.8314H25.6627C26.2554 27.8314 26.747 28.3229 26.747 28.9157C26.747 29.5085 26.2554 30.0001 25.6627 30.0001ZM9.6 11.5663H17.1614C18.6651 11.5663 19.894 10.8434 19.894 8.83379V7.05547C19.8795 5.06029 18.6506 4.3374 17.147 4.3374H9.58554C8.09639 4.3374 6.86747 5.06029 6.86747 7.06993V8.84825C6.86747 10.8434 8.09639 11.5663 9.6 11.5663ZM11.9277 16.988H7.59036C6.99759 16.988 6.50602 16.4964 6.50602 15.9037C6.50602 15.3109 6.99759 14.8193 7.59036 14.8193H11.9277C12.5205 14.8193 13.012 15.3109 13.012 15.9037C13.012 16.4964 12.5205 16.988 11.9277 16.988ZM31.0843 11.5634V20.2381C31.0843 20.8309 30.6072 21.3225 30 21.3225L23.494 21.3369V19.1682L28.9157 19.1538V12.2285L26.6169 11.0863C26.4888 11.0227 26.3747 10.9342 26.2812 10.826C26.1877 10.7178 26.1166 10.5921 26.0722 10.4562C26.0278 10.3203 26.0109 10.1769 26.0225 10.0343C26.0341 9.89183 26.074 9.75304 26.1398 9.62608C26.4 9.09114 27.0651 8.88873 27.6 9.14897L30.4916 10.5948C30.853 10.7827 31.0843 11.1586 31.0843 11.5634Z"
			fill="#002E66"
		/>
	</svg>
);

export const Rating = () => (
	<svg
		width="22"
		height="30"
		viewBox="0 0 22 30"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M12.767 9.95757L14.6825 13.7885C14.9437 14.3109 15.6402 14.8333 16.2207 14.9204L19.6889 15.5009C21.9091 15.8782 22.4315 17.4744 20.8353 19.0706L18.1362 21.7697C17.6863 22.2196 17.4251 23.1047 17.5702 23.7432L18.3393 27.0953C18.9488 29.7364 17.5412 30.7667 15.2049 29.3881L11.9544 27.4581C11.3594 27.1098 10.4017 27.1098 9.80672 27.4581L6.5562 29.3881C4.2199 30.7667 2.81231 29.7364 3.42178 27.0953L4.19087 23.7432C4.33598 23.1193 4.07478 22.2341 3.62493 21.7697L0.925846 19.0706C-0.670389 17.4744 -0.147985 15.8636 2.07223 15.5009L5.54042 14.9204C6.12087 14.8188 6.8174 14.3109 7.07861 13.7885L8.99409 9.95757C10.0244 7.88247 11.7367 7.88247 12.767 9.95757Z"
			fill="#002E66"
		/>
		<path
			opacity="0.4"
			d="M2.17379 12.3345C1.57883 12.3345 1.08545 11.8412 1.08545 11.2462V1.08834C1.08545 0.493382 1.57883 0 2.17379 0C2.76875 0 3.26213 0.493382 3.26213 1.08834V11.2462C3.26213 11.8412 2.76875 12.3345 2.17379 12.3345ZM19.5873 12.3345C18.9923 12.3345 18.4989 11.8412 18.4989 11.2462V1.08834C18.4989 0.493382 18.9923 0 19.5873 0C20.1822 0 20.6756 0.493382 20.6756 1.08834V11.2462C20.6756 11.8412 20.1822 12.3345 19.5873 12.3345ZM10.8805 5.07893C10.2856 5.07893 9.79219 4.58555 9.79219 3.99059V1.08834C9.79219 0.493382 10.2856 0 10.8805 0C11.4755 0 11.9689 0.493382 11.9689 1.08834V3.99059C11.9689 4.58555 11.4755 5.07893 10.8805 5.07893Z"
			fill="#06FF00"
		/>
	</svg>
);
export const Trash = () => (
	<svg
		width="20"
		height="22"
		viewBox="0 0 20 22"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M18.8236 3.98C17.2136 3.82 15.6036 3.7 13.9836 3.61V3.6L13.7636 2.3C13.6136 1.38 13.3936 0 11.0536 0H8.43358C6.10358 0 5.88357 1.32 5.72358 2.29L5.51358 3.57C4.58358 3.63 3.65358 3.69 2.72358 3.78L0.683576 3.98C0.263576 4.02 -0.0364245 4.39 0.00357552 4.8C0.0435755 5.21 0.403575 5.51 0.823575 5.47L2.86358 5.27C8.10358 4.75 13.3836 4.95 18.6836 5.48H18.7636C19.1436 5.48 19.4736 5.19 19.5136 4.8C19.5286 4.60024 19.4648 4.40253 19.3358 4.24925C19.2069 4.09596 19.023 3.99931 18.8236 3.98ZM16.9836 6.89C16.7436 6.64 16.4136 6.5 16.0736 6.5H3.43358C3.09358 6.5 2.75358 6.64 2.52358 6.89C2.29358 7.14 2.16358 7.48 2.18358 7.83L2.80358 18.09C2.91358 19.61 3.05358 21.51 6.54358 21.51H12.9636C16.4536 21.51 16.5936 19.62 16.7036 18.09L17.3236 7.84C17.3436 7.48 17.2136 7.14 16.9836 6.89ZM11.4136 16.5H8.08358C7.67358 16.5 7.33358 16.16 7.33358 15.75C7.33358 15.34 7.67358 15 8.08358 15H11.4136C11.8236 15 12.1636 15.34 12.1636 15.75C12.1636 16.16 11.8236 16.5 11.4136 16.5ZM12.2536 12.5H7.25358C6.84358 12.5 6.50358 12.16 6.50358 11.75C6.50358 11.34 6.84358 11 7.25358 11H12.2536C12.6636 11 13.0036 11.34 13.0036 11.75C13.0036 12.16 12.6636 12.5 12.2536 12.5Z"
			fill="#393939"
		/>
	</svg>
);

export const Star = () => (
	<svg
		width="16"
		height="16"
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M9.36945 1.20194L10.7795 4.02194C10.9695 4.41194 11.4795 4.78194 11.9095 4.86194L14.4595 5.28194C16.0895 5.55194 16.4695 6.73194 15.2995 7.91194L13.3095 9.90194C12.9795 10.2319 12.7895 10.8819 12.8995 11.3519L13.4695 13.8119C13.9195 15.7519 12.8795 16.5119 11.1695 15.4919L8.77945 14.0719C8.34945 13.8119 7.62945 13.8119 7.19945 14.0719L4.80945 15.4919C3.09945 16.5019 2.05945 15.7519 2.50945 13.8119L3.07945 11.3519C3.18945 10.8919 2.99945 10.2419 2.66945 9.90194L0.679454 7.91194C-0.490546 6.74194 -0.110546 5.56194 1.51945 5.28194L4.06945 4.86194C4.49945 4.79194 5.00945 4.41194 5.19945 4.02194L6.60945 1.20194C7.35945 -0.328057 8.59945 -0.328057 9.36945 1.20194Z"
			fill="#FF9100"
		/>
	</svg>
);

export const Users = () => (
	<svg
		width="22"
		height="22"
		viewBox="0 0 22 22"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M14 1H8C3 1 1 3 1 8V14C1 17.78 2.14 19.85 4.86 20.62C5.08 18.02 7.75 15.97 11 15.97C14.25 15.97 16.92 18.02 17.14 20.62C19.86 19.85 21 17.78 21 14V8C21 3 19 1 14 1ZM11 13.17C9.02 13.17 7.42 11.56 7.42 9.58C7.42 7.6 9.02 6 11 6C12.98 6 14.58 7.6 14.58 9.58C14.58 11.56 12.98 13.17 11 13.17Z"
			stroke="white"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
);



export const Depot = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none">
			<path
				d="M16.6667 10.8333C17.125 10.8333 17.5 10.4583 17.5 10C17.5 9.54167 17.125 9.16667 16.6667 9.16667H15.8333V4.16667H16.6667C17.125 4.16667 17.5 3.79167 17.5 3.33333C17.5 2.875 17.125 2.5 16.6667 2.5H3.33333C2.875 2.5 2.5 2.875 2.5 3.33333C2.5 3.79167 2.875 4.16667 3.33333 4.16667H4.16667V9.16667H3.33333C2.875 9.16667 2.5 9.54167 2.5 10C2.5 10.4583 2.875 10.8333 3.33333 10.8333H4.16667V15.8333H3.33333C2.875 15.8333 2.5 16.2083 2.5 16.6667C2.5 17.125 2.875 17.5 3.33333 17.5H16.6667C17.125 17.5 17.5 17.125 17.5 16.6667C17.5 16.2083 17.125 15.8333 16.6667 15.8333H15.8333V10.8333H16.6667ZM10 13.3333C8.61667 13.3333 7.5 12.2333 7.5 10.875C7.5 9.79167 7.91667 9.48333 10 7.08333C12.0583 9.46667 12.5 9.78333 12.5 10.875C12.5 12.2333 11.3833 13.3333 10 13.3333Z"
				fill="white"
			/>
		</svg>
	);
};