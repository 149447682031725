import React, { Fragment, useState } from "react";
import {
	cardBtnType,
	FormType,
	TransactionsType,
	UpdateFormType,
} from "src/helpers/alias";
import User from "src/assets/img/User.svg";
import HqWallet from "src/assets/img/HQWallet.svg";
import Attendant from "src/assets/img/Attendanticon.svg";
import MainProduct from "src/assets/img/MainProduct.svg";
import Wallet from "src/assets/img/walletbtn.svg";
import Rating from "src/assets/img/Ratings.svg";
import { CardButton } from "src/components/Card";
import { useMemo } from "react";
import ProfileCard from "src/components/ProfileCard";
import { LoaderContainer, TableLoader } from "src/components/LoaderContainer";
import useCustomLocation from "src/hooks/useCustomLocation";
import {
	Category,
	CurrencyFormatter,
	handleNotification,
	splitByUpperCase,
	SuccessNotification,
} from "src/helpers/helperFunction";
import AttendantProfile from "src/screens/dashboard/pages/Manage-branch/AttendantProfile";
import BranchReview from "src/screens/dashboard/pages/Manage-branch/BranchReview";
import {
	useFetchAttendentListQuery,
	useFetchSingleHQBranchQuery,
	useUpdateHqBranchDetailsMutation,
	useUpdateWalletPermissionMutation,
} from "src/hq-admin/hq-api/manageHqApiSlice";
import { FlagModal, FormModal, Modal } from "src/components/ModalComp";
// import { AddNewBranch } from "./Components";
import Transaction from "../Transaction";
import { ProductCard } from "src/components/ProductCard";
import { Button } from "src/components/Button";
import { FormInput } from "src/components/inputs";
import { useFormik } from "formik";
import { format } from "date-fns";
import {
	useBranchWalletQuery,
	useGetAllHQTransactionsQuery,
	useHqWalletQuery,
} from "src/hq-admin/hq-api/hqTransactionApiSlice";
import { HeadCellTypes } from ".";

import { UpdateManagersDetails } from "./Components";
import { useUpdateAdminMutation } from "src/api/setttingsApislice";
import { SelectInput } from "src/components/SelectInput";
import { SOURCES, STATUS, TYPES, filterData } from "src/helpers/data";

const BranchData: cardBtnType[] = [
	{
		id: 1,
		icon: User,
		name: "Branch Profile",
	},
	{
		id: 1,
		icon: User,
		name: "Manager's Profile",
	},

	{
		id: 3,
		icon: Attendant,
		name: "Attendant Profile",
	},
	{
		id: 4,
		icon: Rating,
		name: "Ratings and Reviews",
	},
	{
		id: 5,
		icon: Wallet,
		name: "Wallet Info",
	},
	{
		id: 5,
		icon: HqWallet,
		name: "Transactions",
	},
	{
		id: 6,
		icon: MainProduct,
		name: "Branch Main Products",
	},
];
const headCells: readonly HeadCellTypes[] = [
	{
		id: "transactionDate",
		minWidth: 170,
		label: "Transaction Date",
	},
	{
		id: "walletId",
		minWidth: 170,
		label: "Wallet ID",
	},
	{
		id: "referenceId",
		minWidth: 170,
		label: "Reference",
	},
	{
		id: "amount",
		minWidth: 170,
		label: "Transaction Amount (N)",
	},

	{
		id: "status",
		minWidth: 170,
		label: "Status",
	},
];

export default function SingleBranch() {
	const [tabName, setTabName] = useState<string>("branch profile");
	const [showModal, setShowModal] = useState(false);
	const [showManageUpdate, setShowManageUpdates] = useState(false);
	const { slicedPath } = useCustomLocation();
	const branchResult = useFetchSingleHQBranchQuery(slicedPath[2]);
	const [page, setPage] = useState(1);
	const [info, setInfo] = useState({
		source: "user_transfer",
	});
	// const results = useFetchFuelLevelQuery({});

	const result = useFetchAttendentListQuery({
		stationBranch: slicedPath[2],
	});

	const pumpAttendant = useMemo(() => result?.data, [result]);

	const station = branchResult?.data?.station;

	const [updateBranchDetails, updateBranchDetailsResult] =
		useUpdateHqBranchDetailsMutation();
	const [updateBranchManagerDetailsMutation, updateBranchDetailsManagerResult] =
		useUpdateAdminMutation();

	async function updateBranch(values: FormType) {
		try {
			const response = await updateBranchDetails({
				id: slicedPath[2],
				...values,
			}).unwrap();

			SuccessNotification("Successful");
			setShowModal(() => false);
		} catch (error: any) {
			setShowModal(() => false);
			handleNotification(error);
		}
	}

	async function updateBranchManagerDetails(values: FormType) {
		try {
			const response = await updateBranchManagerDetailsMutation({
				id: station?.manager?._id,
				...values,
			}).unwrap();

			SuccessNotification("Successful");
			setShowModal(() => false);
		} catch (error: any) {
			setShowModal(() => false);
			handleNotification(error);
		}
	}

	// HANDLE CHANGE FOR TABLE TAB
	const handleSelectChange = (event: { target: { value: string } }) => {
		setInfo((prev) => {
			return { source: event.target.value };
		});
	};

	const handledAPIResponse = useMemo(() => {
		return {
			profileData: {
				name: station?.name,
				systemCode: station?.systemCode,
				phoneNumber: station?.phoneNumber,
				// email: station?.email,
				address: station?.location?.address,
				LGA: station?.location?.lga,
				state: station?.location?.state,
				latitude: station?.location?.latitude,
				longitude: station?.location?.longitude,
				status: station?.status,

				openingHours: station?.config?.openTime?.from,
				closeHours: station?.config?.openTime?.to,
				pumpCount: station?.config?.pumpCount,

				// hqName: station?.stationHQ?.name,
				// hqEmail: station?.stationHQ?.email,
				// stationPhoneNumber: station?.phoneNumber,
			},
			manager: {
				firstName: station?.manager?.firstName,
				lastName: station?.manager?.lastName,
				email: station?.manager?.email,
				phoneNumber: station?.manager?.phoneNumber,
			},
			imageUrl: station?.logo?.url,

			pumpAttendants: station?.pumpAttendants,
			rating: station?.ratings,
			account: station?.wallets?.availableBalance,
			config: station?.config,
			walletInfo: {
				walletName: station?.wallets?.walletName,
				walletNumber: station?.wallets?.walletNumber,
				bankName: station?.wallets?.bankName,
				bankReference: station?.wallets?.bankReferenceNumber,
			},
		};
	}, [station]);

	const tabDetails = tabName.toLowerCase();

	const allTransactionsResult = useGetAllHQTransactionsQuery({
		stationBranch: slicedPath[2],
		limit: 8,
		orderBy: "createdAt:desc",
		populate: "stationBranch",
		page,
		...(STATUS.includes(info?.source) && { status: info.source }),
		...(SOURCES.includes(info?.source) && { source: info.source }),
		...(TYPES.includes(info?.source) && { type: info.source }),
	});
	const handleChangePage = (e: any, newPage: number) => {
		setPage(newPage);
	};

	const handledTransactionResponse = useMemo(() => {
		const transactions = allTransactionsResult?.data || [];

		return transactions?.data?.reduce(
			(acc: { [index: string]: string }[], cur: TransactionsType | any) => [
				...acc,
				{
					...cur,
					transactionDate: format(
						new Date(cur.createdAt),
						"d/MM/yyyy hh:mm:ss"
					),
					referenceId: cur?.reference,
					reason: cur?.purpose,

					walletId: cur?.stationBranch?.systemCode,
					type: cur?.meta?.transactionType,

					amount: CurrencyFormatter(Number(cur?.amount)),

					status: (
						<p
							className={`${
								cur?.status?.toString().toLowerCase() === "pending"
									? "text-yellow-500"
									: cur.status.toString().toLowerCase() === "successful"
									? "text-green-500"
									: "text-red-500"
							}`}>
							{cur?.status}
						</p>
					),

					accountName: cur?.meta?.accountName,
				},
			],
			[]
		);
	}, [allTransactionsResult]);

	return (
		<section>
			{/* <LoaderContainer /> */}
			<article className="w-full h-screen px-2">
				<div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4  py-3">
					<>
						{BranchData.map((dt) => (
							<Fragment>
								<CardButton
									name={dt.name}
									icon={dt.icon}
									height={"98px"}
									activeBtn={tabName}
									onClick={() => setTabName(dt.name)}
									// showCard={showCard}
								/>
							</Fragment>
						))}
					</>
				</div>

				<LoaderContainer data={branchResult}>
					{tabDetails === "branch profile" ? (
						<ProfileCard
							data={handledAPIResponse.profileData || {}}
							onClick={() => setShowModal(true)}
							showImage={false}
							showBanner={Boolean(handledAPIResponse?.imageUrl)}
							imageURL={handledAPIResponse?.imageUrl}
							showButton={true}
							flag={true}
						/>
					) : null}

					{tabDetails === "manager's profile" ? (
						<ProfileCard
							data={handledAPIResponse.manager || {}}
							onClick={() => setShowManageUpdates(true)}
							showImage={false}
							showBanner={Boolean(handledAPIResponse?.imageUrl)}
							imageURL={handledAPIResponse?.imageUrl}
							showButton={true}
							flag={false}
						/>
					) : null}
					{tabDetails === "attendant profile" ? (
						<AttendantProfile attendantData={pumpAttendant?.data} />
					) : null}

					{tabDetails === "ratings and reviews" ? (
						<BranchReview ratings={handledAPIResponse.rating} />
					) : null}
					{tabDetails === "wallet info" ? (
						<BranchAccountBalance
							account={handledAPIResponse.account}
							info={handledAPIResponse}
						/>
					) : null}

					{tabDetails === "transactions" ? (
						<div>
							<div className="w-fit flex gap-x-4 items-center mr-auto px-4 h-24 py-4 mt-4">
								<h6>Filter By: </h6>{" "}
								<SelectInput
									tabData={filterData}
									filteredValue={info.source}
									onChange={handleSelectChange}
								/>
							</div>

							<Transaction
								headCells={headCells}
								transactions={handledTransactionResponse}
								transactionResult={allTransactionsResult}
								handleChangePage={handleChangePage}
							/>
						</div>
					) : null}
					{tabDetails === "branch main products" ? (
						<ProductCard data={handledAPIResponse.config} show />
					) : null}
				</LoaderContainer>
				{showModal ? (
					<FormModal
						name="Update branch details"
						onClick={() => setShowModal((prevState) => !prevState)}>
						<AddNewBranch
							makeApiRequest={(values: any) => updateBranch(values)}
							apiResult={updateBranchDetailsResult}
							initalValue={{
								name: station?.name,
								phoneNumber: station?.phoneNumber,
							}}
						/>
					</FormModal>
				) : null}

				{showManageUpdate ? (
					<FormModal
						name="Update branch manager's details"
						onClick={() => setShowManageUpdates((prevState) => !prevState)}>
						<UpdateManagersDetails
							makeApiRequest={(values: any) =>
								updateBranchManagerDetails(values)
							}
							apiResult={updateBranchDetailsManagerResult}
							initalValue={{
								firstName: station?.manager?.firstName,
								lastName: station?.manager?.lastName,
								email: station?.manager?.email,
								// phoneNumber: station?.phoneNumber,
							}}
						/>
					</FormModal>
				) : null}
			</article>
		</section>
	);
}

const BranchAccountBalance = (props: {
	account: string;
	info: { [index: string]: string | any };
}) => {
	const { slicedPath } = useCustomLocation();
	const result = useBranchWalletQuery(slicedPath[2]);
	const [updateWalletPermissionMutation, updateWalletResult] =
		useUpdateWalletPermissionMutation();
	const [showStrictAccount, setStrictAccount] = useState(false);
	const [showLockAccount, setShowLockAccount] = useState(false);

	const walletRestrictionStatus = result?.data?.canWithdraw;
	const walletStatus = result?.data?.locked;

	function handleStrictWalletState() {
		setStrictAccount((prevState) => !prevState);
	}
	function handleLockedWalletState() {
		setShowLockAccount((prevState) => !prevState);
	}

	async function updateWalletPermission() {
		try {
			const response = await updateWalletPermissionMutation({
				id: slicedPath[2],
				canWithdraw: walletRestrictionStatus ? false : true,
			}).unwrap();
			handleStrictWalletState();

			SuccessNotification("Successful");
		} catch (error: any) {
			handleStrictWalletState();
			handleNotification(error);
		}
	}

	async function updateWalletStatus() {
		try {
			const response = await updateWalletPermissionMutation({
				id: slicedPath[2],
				locked: walletStatus ? false : true,
			}).unwrap();
			handleLockedWalletState();
			SuccessNotification("Successful");
		} catch (error: any) {
			handleLockedWalletState();
			handleNotification(error);
		}
	}

	return (
		<TableLoader tableData={Object.keys(result?.data || {})} data={result}>
			<div className="p-4 basis-[60%] rounded-[10px] bg-white grid grid-cols-1 gap-x-10 justify-items-center content-center mt-6 pl-6">
				<div className="grid grid-cols-2 gap-10">
					<div className="text-center text-[#002E66]">
						<h3 className="text-[14px]my-">Available Balance</h3>
						<h2 className="text-[24px] font-bold">
							{CurrencyFormatter(Number(result?.data?.availableBalance))}
						</h2>
					</div>
					<div className="text-center text-[#002E66]">
						<h3 className="text-[14px]">Branch Balance</h3>
						<h2 className="text-[24px] text-center font-bold">
							{CurrencyFormatter(Number(result?.data?.branchesBalance))}
						</h2>
					</div>
				</div>
				<div className="w-full  grid grid-cols-3 gap-y-6 mt-14">
					{/* {Object.keys(props?.info.walletInfo)?.map((dt, i) => { */}
					<div>
						<h2 className="text-black capitalize">wallet Name</h2>
						<h2 className="text-[#002E66] capitalize">
							{result?.data?.walletName || 0}
						</h2>
					</div>
					<div>
						<h2 className="text-black capitalize">Account Number</h2>
						<h2 className="text-[#002E66] capitalize">
							{result?.data?.walletNumber || 0}
						</h2>
					</div>
					<div>
						<h2 className="text-black capitalize">Bank name</h2>
						<h2 className="text-[#002E66] capitalize">
							{result?.data?.bankName || 0}
						</h2>
					</div>
				</div>
				<div className="lg:w-1/2 mx-auto flex items-center justify-between mt-24 gap-x-6   px-4">
					<Button
						className="h-full w-full font-bold bg-[#D0D5DD] p-3 rounded-lg hover: text-[#002E66] flex items-center justify-center"
						text={
							walletRestrictionStatus
								? "Activate Strict Mode"
								: "Activate Free Mode"
						}
						onClick={handleStrictWalletState}
						type="button"
					/>

					<Button
						className="h-full w-full font-bold bg-[#D0D5DD] p-3 rounded-lg hover: text-[#002E66] flex items-center justify-center"
						text={walletStatus ? "Lock Account" : "unlock Account"}
						type="button"
						onClick={handleLockedWalletState}
					/>
				</div>

				{showStrictAccount ? (
					<Modal>
						<FlagModal
							info={`Are you sure you want ${
								walletRestrictionStatus
									? "Activate Strict Mode"
									: "Activate Free Mode"
							} ?`}
							onClose={handleStrictWalletState}
							onConfirmation={updateWalletPermission}
							showModal={updateWalletResult?.isLoading}
						/>
					</Modal>
				) : null}
				{showLockAccount ? (
					<Modal>
						<FlagModal
							info={`Are you sure you want to ${
								walletStatus ? "Lock Account" : "unlock Account"
							}?`}
							onClose={handleLockedWalletState}
							onConfirmation={updateWalletStatus}
							showModal={updateWalletResult?.isLoading}
						/>
					</Modal>
				) : null}
			</div>
		</TableLoader>
	);
};

export const AddNewBranch = (props: any) => {
	const initValue = props.initalValue;

	const Formik = useFormik<UpdateFormType>({
		initialValues: {
			...initValue,
		},
		validateOnBlur: true,
		validateOnChange: true,
		// validationSchema: AddbranchValidation[step],

		onSubmit: (values) => {
			props.makeApiRequest(values);
		},
	});

	const styles =
		"h-[38px] py-6 rounded-[38px] w-full border border-gray-300 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-500 px-4 text-[14px] bg-[#D9D9D9]";
	const labelStyles =
		"block mb-[6px] text-black text-start font-normal text-[14px] text-black ml-5 my-6";

	const FormData = [
		{
			id: "name",
			name: "Branch name",
			type: "text",
			styles: `${styles} ${
				Formik.errors.name && Formik.touched.name
					? "border-red-500"
					: "border-gray-300"
			}`,
			labelStyles: labelStyles,
			onChange: Formik.handleChange,
			value: Formik.values.name,
			onBlur: Formik.handleBlur,
			// disabled: addNewBranchResult?.isLoading,
			error: Formik.errors.name,
			touched: Formik.touched.name,
		},
		{
			id: "phoneNumber",
			name: "Branch contact info",
			type: "text",
			styles: `${styles} ${
				Formik.errors.phoneNumber && Formik.touched.phoneNumber
					? "border-red-500"
					: "border-gray-300"
			}`,
			labelStyles: labelStyles,
			onChange: Formik.handleChange,
			value: Formik.values.phoneNumber,
			onBlur: Formik.handleBlur,
			// disabled: addNewBranchResult?.isLoading,
			error: Formik.errors.phoneNumber,
			touched: Formik.touched.phoneNumber,
		},
	];

	return (
		<form
			onSubmit={Formik.handleSubmit}
			className="w-full flex flex-col justify-center items-center px-4 h-full">
			<div className="grid grid-cols-1 w-full gap-x-2 content-center">
				{FormData.slice(0, 6).map((dt, i) => (
					<FormInput
						id={dt.id}
						name={dt.name}
						type={dt.type}
						styles={dt.styles}
						labelStyles={dt.labelStyles}
						onChange={dt.onChange}
						value={dt.value}
						onBlur={dt.onBlur}
						// disabled={dt.disabled}
						error={dt.error}
						touched={dt.touched}
					/>
				))}
			</div>

			<div className="w-full">
				<Button
					text="Update Branch Details"
					// disabled={addNewBranchResult?.isLoading}
					showModal={props.apiResult?.isLoading}
					className="h-[41px] mt-6 font-bold text-white rounded-[38px] w-full hover: bg-[#002E66]"
					type="submit"
				/>
			</div>
		</form>
	);
};


