import React, { ReactNode } from "react";
import Image from "./Image";
import Logo from "src/assets/img/Logo.jpeg";


export default function AuthWrapper({
	children,
	name,
}: {
	children: ReactNode;
	name: string;
}) {
	return (
		<section className="w-screen h-screen">
			<article className="h-full w-full flex flex-col justify-center py-2 backgroundImage">
				<div className=" mx-auto flex h-full rounded-[30px] flex-col items-center w-[90%] sm:max-w-[570px] bg-white">
					<div className="mt-[53px] h-[30vh]">
						<div className="mb-6 ">
							<Image
								image={Logo}
								width={130}
								height={100}
								styles="mx-auto object-contain bg-red-900"
							/>
						</div>
						<h1 className="text-[20px] font-normal leading-[30px] text-black">
							{name}
						</h1>
					</div>
					<div className="w-full h-[50vh]">{children}</div>
				</div>
			</article>
		</section>
	);
}
