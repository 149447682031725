import React, { useMemo, useState } from "react";
import { TableLoader } from "src/components/LoaderContainer";
import EnhancedTable from "src/components/Table";
import { forEnums, handleDateFormat } from "src/helpers/helperFunction";
import { useAuth } from "src/hooks/useAuth";
import useHandleRowClick from "src/hooks/useHandleRowClick";
import useHandleSelectAllClick from "src/hooks/useHandleSelectAllClick";
import useHandleSingleSelect from "src/hooks/useHandleSingleSelect";
import useIsSelected from "src/hooks/useIsSelected";
import { useFetchAllBranchesNotificationQuery } from "src/hq-admin/hq-api/notificationApiSlice";
import { NotificationPreview } from "./AutoComplete";

const headCells: readonly any[] = [
	{
		id: "title",
		minWidth: 170,
		label: "Title",
	},
	{
		id: "message",
		minWidth: 170,
		label: "Message",
	},
	{
		id: "for",
		minWidth: 170,
		label: "For",
	},
	{
		id: "createdAt",
		minWidth: 170,
		label: "Date",
	},
];

export default function BranchesNotification() {
	const [showModal, setShowModal] = useState(false);
	const [previewData, setPreviewData] = useState({});
	const { user } = useAuth();
	const { handleSelectAllClick, selected, setSelected } =
		useHandleSelectAllClick([]);
	const [page, setPagination] = useState(1);
	const { isSelected } = useIsSelected(selected);
	const { handleClick } = useHandleSingleSelect(selected, setSelected);
	const { handleRowClick } = useHandleRowClick(fn);

	const notificationResult = useFetchAllBranchesNotificationQuery({
		stationHQ: user?.stationHQ?.id,
		for: "stationBranch",
		populate: "stationBranch",
		page,
	});

	function fn(data: any) {
		setShowModal((prevState) => !prevState);

		setPreviewData(() => data);
	}
	const handleChangePage = (event: unknown, newPage: number) => {
		setPagination((prev) => newPage);
	};

	const handledAPIResponse = useMemo(() => {
		const hqProfile = notificationResult?.currentData?.data || [];

		return hqProfile?.data?.reduce(
			(
				acc: { [index: string]: string }[],
				cur: {
					[index: string]: string | any;
				}
			) => [
				...acc,
				{
					id: cur?.id,
					title: cur?.title,
					message: cur?.message,
					for: cur?.stationBranch?.name,
					createdAt: handleDateFormat(cur?.createdAt),
				},
			],
			[]
		);
	}, [notificationResult]);
	let dataToChildren: any = {
		rows: handledAPIResponse || [],
		headCells,
		handleRowClick,
		showFlag: false,
		showCheckBox: false,
		isSelected,
		handleClick,
		handleSelectAllClick,
		selected,
		handleChangePage,
		paginationData: {
			totalPage: notificationResult?.currentData?.data.totalPages,
			limit: notificationResult?.currentData?.data.limit,
			page: notificationResult?.currentData?.data.page,
		},
	};
	return (
		<section>
			<article>
				<TableLoader
					data={notificationResult || {}}
					tableData={handledAPIResponse || []}>
					<div className="h-full w-full">
						<div className="relative">
							<EnhancedTable {...dataToChildren} />
						</div>
					</div>
				</TableLoader>
			</article>

			{showModal ? (
				<NotificationPreview data={previewData} setShowModal={setShowModal} />
			) : null}
		</section>
	);
}
