import React, { useMemo, useState } from "react";

import { TableLoader } from "src/components/LoaderContainer";
import { FormModal } from "src/components/ModalComp";
import EnhancedTable from "src/components/Table";
import { forEnums, handleDateFormat } from "src/helpers/helperFunction";
import useHandleRowClick from "src/hooks/useHandleRowClick";
import useHandleSelectAllClick from "src/hooks/useHandleSelectAllClick";
import useHandleSingleSelect from "src/hooks/useHandleSingleSelect";
import useIsSelected from "src/hooks/useIsSelected";
import HQ from "src/assets/img/hq.svg";
import { cardBtnType } from "src/helpers/alias";
import { CardButton } from "src/components/Card";
import { useFetchAllHQNotificationQuery } from "src/hq-admin/hq-api/notificationApiSlice";
import { useAuth } from "src/hooks/useAuth";
import { Tabs, Tab } from "@mui/material";
import { useFetchHQBranchQuery } from "src/hq-admin/hq-api/manageHqApiSlice";
import { SendNotificationModal } from "./components/SendNotifiction";
import BranchesNotification from "./components/BranchesNotification";
import { useDebounce } from "src/hooks/useDebounce";
import { SearchInput } from "src/components/inputs";

const HQData: cardBtnType[] = [
	{
		id: 2,
		icon: HQ,
		name: "Send to Branch",
	},
];
const headCells: readonly any[] = [
	{
		id: "title",
		minWidth: 170,
		label: "Title",
	},
	{
		id: "message",
		minWidth: 170,
		label: "Message",
	},
	{
		id: "for",
		minWidth: 170,
		label: "For",
	},
	{
		id: "createdAt",
		minWidth: 170,
		label: "Date",
	},
];

export default function Notification() {
	const { user } = useAuth();

	const [cardName] = useState("");
	const [showAddModal, setShowAddModal] = useState<boolean>(false);

	const { handleSelectAllClick, selected, setSelected } =
		useHandleSelectAllClick([]);
	const [page, setPagination] = useState(1);
	const { isSelected } = useIsSelected(selected);
	const { handleClick } = useHandleSingleSelect(selected, setSelected);
	const { handleRowClick } = useHandleRowClick();
	const [value, setValue] = useState(0);
	const [filteredValue, setFilteredValue] = useState<string>("");
	const { debouncedValue } = useDebounce(filteredValue, 700);

	const notificationResult = useFetchAllHQNotificationQuery({
		...(debouncedValue && { search: debouncedValue }),
		// for: "stationBranch",
		stationBranch: user?.stationHQ?.admin,
		page,
		orderBy: "createdAt:desc",
	});
	// const notificationResult = useFetchAllNotificationQuery({
	// 	page: page.newPage,
	// });

	// API TO GET ALL HQ INFORMATION

	// console.log(notificationResult);

	const handleChangePage = (event: unknown, newPage: number) => {
		setPagination((prev) => newPage);
	};

	const handledAPIResponse = useMemo(() => {
		const hqProfile = notificationResult?.currentData?.data || [];

		return hqProfile?.data?.reduce(
			(
				acc: { [index: string]: string }[],
				cur: {
					[index: string]: string;
				}
			) => [
				...acc,
				{
					id: cur?.id,
					title: cur?.title,
					message: cur?.message,
					for: forEnums[cur?.for],
					createdAt: handleDateFormat(cur?.createdAt),
				},
			],
			[]
		);
	}, [notificationResult]);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	const fetchAllBranchResult = useFetchHQBranchQuery({});

	return (
		<section>
			<article>
				{notificationResult?.isSuccess ? (
					<div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4  py-3">
						{HQData.map((dt) => (
							<CardButton
								name={dt.name}
								icon={dt.icon}
								link={dt.link}
								height={"98px"}
								onClick={() => {
									setShowAddModal(true);
								}}
							/>
						))}
					</div>
				) : null}

				<div className="h-fit w-full bg-white">
					<div className="flex w-[50%] h-11  max-w-[562px] items-center gap-2 rounded-[15px] border-2 border-[#D0D5DD] bg-[#D9D9D9] px-[18px] mt-4 mb-6">
						<SearchInput
							name="branch-search"
							placeholder="Search "
							value={filteredValue}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
								const target = e.target;
								setFilteredValue(target.value);
							}}
						/>
					</div>

					<BranchesNotification />
				</div>

				{showAddModal ? (
					<FormModal
						name="Send Notification"
						onClick={() => setShowAddModal(false)}>
						<SendNotificationModal
							name={cardName}
							close={() => setShowAddModal(false)}
							data={fetchAllBranchResult?.data?.data}
						/>
					</FormModal>
				) : null}
			</article>
		</section>
	);
}
