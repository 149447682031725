import { SetStateAction, useCallback, useMemo, useState } from "react";
import { Button } from "src/components/Button";
import { FormModal } from "src/components/ModalComp";
import {
	CurrencyFormatter,
	ErrorNotification,
	SuccessNotification,
} from "src/helpers/helperFunction";
import Wallet from "src/assets/img/walletbtn.svg";
import {
	useApproveWithdrawalRequestMutation,
	useGetAllWidthdrawRequestQuery,
} from "src/hq-admin/hq-api/hqTransactionApiSlice";
import { LoaderContainer } from "src/components/LoaderContainer";
import { format } from "date-fns";
import { Tabs, Tab } from "@mui/material";
import RequestDetails from "./components/RequestDetails";
import NotificationIndicator from "./components/Notification";

const WithDrawal = () => {
	const [showNotification, setShowNotification] = useState<boolean>(false);
	const [selectedItem, setSelectedItem] = useState({});
	const [value, setValue] = useState(0);

	const allWithdrawalRequestQuery = useGetAllWidthdrawRequestQuery({
		populate: "stationBranch",
	});

	const branchWithdrawRequest = useCallback(
		(status: string) =>
			allWithdrawalRequestQuery?.data?.data?.data?.filter(
				(item: any) => item?.status === status
			),
		[allWithdrawalRequestQuery]
	);

	const handleWidthDrawal = (item: any) => {
		setShowNotification((prevState) => !prevState);

		setSelectedItem(item);
	};

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	return (
		<section>
			<Tabs value={value} onChange={handleChange} aria-label="Order list">
				<Tab label="Pending" tabIndex={0} />
				<Tab label="Accepted" tabIndex={1} />
				<Tab label="Rejected" tabIndex={2} />
				{/* <Tab label="Completed" tabIndex={3} /> */}
			</Tabs>
			<LoaderContainer data={allWithdrawalRequestQuery}>
				<article>
					{value === 0 ? (
						<>
							{branchWithdrawRequest("pending")?.length > 0 ? (
								<>
									{branchWithdrawRequest("pending")?.map((item: any) => {
										return (
											<button
												onClick={() => handleWidthDrawal(item)}
												className="w-full py-5 mt-3 shadow-lg bg-white rounded-lg flex items-center gap-10 px-6 font-semibold text-primary text-sm text-start relative">
												<img src={Wallet} alt="wallet" />
												<RequestDetails
													name={item?.stationBranch?.name}
													amount={item?.payload?.amount}
													date={item?.createdAt}
												/>
												<NotificationIndicator status="pending" />
											</button>
										);
									})}
								</>
							) : (
								<div className="w-full h-56  flex items-center justify-center font-bold ">
									No pending withdrawal Request Available
								</div>
							)}
						</>
					) : null}
					{value === 1 ? (
						<>
							{branchWithdrawRequest("approved")?.length > 0 ? (
								<>
									{branchWithdrawRequest("approved")?.map((item: any) => {
										return (
											<button className="w-full py-5 mt-3 shadow-lg bg-white rounded-lg flex items-center gap-10 px-6 font-semibold text-primary text-sm text-start relative">
												<img src={Wallet} alt="wallet" />
												<RequestDetails
													name={item?.stationBranch?.name}
													amount={item?.payload?.amount}
													desc={item?.payload?.purpose}
													date={item?.createdAt}
												/>
												<NotificationIndicator status="approved" />
											</button>
										);
									})}
								</>
							) : (
								<div className="w-full h-56  flex items-center justify-center font-bold ">
									No approved withdrawal Request Available
								</div>
							)}
						</>
					) : null}
					{value === 2 ? (
						<>
							{branchWithdrawRequest("decline")?.length > 0 ? (
								<>
									{branchWithdrawRequest("decline")?.map((item: any) => {
										return (
											<button
												onClick={() => handleWidthDrawal(item)}
												className="w-full py-5 mt-3 shadow-lg bg-white rounded-lg flex items-center gap-10 px-6 font-semibold text-primary text-sm text-start relative">
												<img src={Wallet} alt="wallet" />
												<RequestDetails
													name={item?.stationBranch?.name}
													amount={item?.payload?.amount}
													date={item?.createdAt}
												/>
												<NotificationIndicator status="rejected" />
											</button>
										);
									})}
								</>
							) : (
								<div className="w-full h-56  flex items-center justify-center font-bold ">
									No declined Request
								</div>
							)}
						</>
					) : null}
				</article>
			</LoaderContainer>

			{showNotification ? (
				<FormModal
					name="Withdrawal request"
					onClick={() => setShowNotification((prevState) => !prevState)}>
					<WidthDrawModal
						toggleState={() => setShowNotification((prevState) => !prevState)}
						selectedItem={selectedItem}
					/>
				</FormModal>
			) : null}
		</section>
	);
};

export default WithDrawal;

const WidthDrawModal = ({ selectedItem, toggleState }: any) => {
	const [approveWidthdrawalMutation, widthdrawalRequest] =
		useApproveWithdrawalRequestMutation();
	const [approvedState, setApprovedState] = useState(false);

	const handleApprovedRequest = async (isApproved: boolean) => {
		setApprovedState(() => isApproved);
		try {
			const response = await approveWidthdrawalMutation({
				id: selectedItem?.id,
				body: { status: isApproved ? "approved" : "rejected" },
			}).unwrap();
			SuccessNotification(response.message);
		} catch (error: any) {
			ErrorNotification(error?.data?.message);
			console.log(error, "error");
		}
		toggleState();
	};
	return (
		<div className="w-full flex flex-col justify-center py-4 px-6 h-full text-sm gap-6">
			<div>
				<h1 className="text-lightgray">Branch Name</h1>
				<p>{selectedItem?.stationBranch?.name || "name"}</p>
			</div>
			<div>
				<h1 className="text-lightgray">Description</h1>
				<p>{selectedItem?.payload?.purpose}</p>
			</div>
			<div>
				<h1 className="text-lightgray">Amount</h1>
				<p>{CurrencyFormatter(selectedItem?.payload?.amount)}</p>
			</div>
			<div>
				<h1 className="text-lightgray">Request Date</h1>
				<p>{format(new Date(selectedItem?.createdAt), "d/MM/yyyy h:m:s a")}</p>
			</div>
			<div>
				<div className="w-full h-[41px] mb-[19px]">
					<Button
						onClick={() => handleApprovedRequest(true)}
						text="Approve"
						className="h-full w-full font-bold  bg-primary rounded-full text-white flex items-center justify-center"
						type="button"
						showIcon={false}
						showModal={widthdrawalRequest.isLoading && approvedState}
					/>
				</div>
				<div className="w-full h-[41px]">
					<Button
						text="Decline"
						className="h-full w-full font-bold bg-red-600 border border-red-600 text-white rounded-full flex items-center justify-center"
						type="button"
						showIcon={false}
						showModal={widthdrawalRequest.isLoading && !approvedState}
						onClick={() => handleApprovedRequest(false)}
					/>
				</div>
			</div>
		</div>
	);
};
