import { Fragment, useState } from "react";
import {
	CurrencyFormatter,
	splitByUpperCase,
} from "src/helpers/helperFunction";
import CustomizedSwitches from "./SwitchComp";
import CustomSlider from "./CustomSlider";
import { Button } from "./Button";
import { FormModal } from "./ModalComp";
import AddProducts from "./AddProducts";
import AddAmenities from "./AddAmenities";
import useCustomLocation from "src/hooks/useCustomLocation";
import { useAuth } from "src/hooks/useAuth";
import { LoaderContainer } from "./LoaderContainer";
import {
	useFetchFuelLevelQuery,
	useFetchMeterReadingsQuery,
} from "src/hq-admin/hq-api/manageHqApiSlice";

export function ProductCard(props: any) {
	const { amenities, ...rest } = props?.data;
	const { slicedPath } = useCustomLocation();
	const queryResult = useFetchFuelLevelQuery({ stationBranch: slicedPath[2] });
	const result = useFetchMeterReadingsQuery({ stationBranch: slicedPath[2] });

	const [showProductModal, setShowProductModal] = useState(false);
	const [showAmenities, setShowAmenities] = useState(false);

	function handleShowModal() {
		setShowProductModal((prevState) => !prevState);
	}
	function handleShowAmenities() {
		setShowAmenities((prevState) => !prevState);
	}

	return (
		<div className="w-full h-fit bg-white shadow-lg rounded-lg text-[14px] py-6">
			<div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-y-10 py-4 md:gap-x-2 text-start px-4 lg:px-16">
				{rest?.products?.map((dt: any, i: number) => (
					<Fragment key={i + 1}>
						<div>
							<h2 className="text-black capitalize">
								{splitByUpperCase(dt?.name)}
							</h2>
							<span className="block bg-[#737587] h-0.5 w-20 my-1.5 rounded-lg capitalize"></span>
							<h2 className="text-[#002E66] capitalize">
								{CurrencyFormatter(dt?.price ?? 0)}
							</h2>

							<h2 className="text-[#002E66] capitalize">
								Available:
								{!dt?.isAvailable ? " NO" : " YES"}
							</h2>
							{/* {props?.show ? (
								<CustomizedSwitches
									defaultChecked={dt?.isAvailable}
									off="off"
									on="on"
								/>
							) : null} */}
						</div>
					</Fragment>
				))}
			</div>
			<div className="py-6 px-10">
				<h2 className="font-bold text-lg text-left">Product Level Readings</h2>
				<LoaderContainer data={result}>
					<div className="w-full h-full grid grid-cols-2 lg:grid-cols-4 ">
						{queryResult?.data?.data?.map((item: any, i: number) => (
							<div className="p-4 flex flex-col items-center" key={i + 1}>
								<h3 className="capitalize pb-3">
									{item?.product} {Math.ceil(item?.fuelLevelPercentage) || 0}%
								</h3>
								<div
									className={`h-3 py-1 w-full rounded-lg  flex items-center overflow-hidden bg-gray`}>
									<span
										className={`block h-3 ${
											Math.ceil(item?.fuelLevelPercentage) >= 15
												? "bg-[#002E66]"
												: "bg-red-500"
										} `}
										style={{
											width: `${Math.ceil(item?.fuelLevelPercentage)}%`,
										}}></span>
								</div>
							</div>
						))}
					</div>
				</LoaderContainer>
			</div>
			<div className="w-full grid grid-cols-3 mt-6 gap-x-6 px-4">
				<Button
					className="h-full w-full font-bold bg-[#D0D5DD] p-3 rounded-lg hover: text-[#002E66] flex items-center justify-center"
					text={"Add Product"}
					onClick={handleShowModal}
					type="button"
				/>

				<Button
					className="h-full w-full font-bold bg-[#D0D5DD] p-3 rounded-lg hover: text-[#002E66] flex items-center justify-center"
					text="Add NFR"
					type="button"
					onClick={handleShowAmenities}
				/>
			</div>
			{showProductModal ? (
				<FormModal name="Add Products" onClick={handleShowModal}>
					<AddProducts onClose={handleShowModal} />
				</FormModal>
			) : null}
			{showAmenities ? (
				<FormModal name="Add NFR" onClick={handleShowAmenities}>
					<AddAmenities onClose={handleShowAmenities} />
				</FormModal>
			) : null}
		</div>
	);
}
