import { InvalidateTag, providesTagList } from "src/helpers/helperFunction";
import { HQ_API_ENPOINTS, RTKTAG } from "src/helpers/Constant";
import { apiSlice } from "src/api/apiSlice";

export const hqNotificationAPISlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		fetchAllHQNotification: builder.query({
			query: (params) =>
				({
					url: `${HQ_API_ENPOINTS.NOTIFICATION}/?limit=7&orderBy=createdAt:desc`,
					params,
				} as any),
			providesTags: [{ type: RTKTAG.NOTIFICATION }] as any,
		}),
		fetchAllBranchesNotification: builder.query({
			query: (params) =>
				({
					url: HQ_API_ENPOINTS.NOTIFICATION,
					params,
				} as any),
			providesTags: [{ type: RTKTAG.NOTIFICATION }] as any,
		}),
		sendHQNotification: builder.mutation({
			query: (body: any): string | any => ({
				url: HQ_API_ENPOINTS.NOTIFICATION,
				method: "POST",
				body,
			}),
			invalidatesTags: [{ type: RTKTAG.NOTIFICATION }] as any,
		}),
	}),
});

export const {
	useFetchAllHQNotificationQuery,
	useSendHQNotificationMutation,
	useFetchAllBranchesNotificationQuery,
} = hqNotificationAPISlice;
