import { Avatar } from "@mui/material";
import { useState } from "react";
import { FlagModal, Modal } from "src/components/ModalComp";
import useHandleSelectAllClick from "src/hooks/useHandleSelectAllClick";
import { useNavigate } from "react-router-dom";

export default function AttendantProfile(props: {
	attendantData: { [index: string]: string | any }[];
}) {
	const { selected } = useHandleSelectAllClick(props.attendantData);
	const [showFlagModal, setShowFlagModal] = useState<boolean>(false);

	const navigate = useNavigate();

	function fn(data: { [index: string]: string | number }) {
		return function () {
			navigate(`/manage-attendants/${data?.id}`, { state: data });
		};
	}

	return (
		<section className="w-full h-screen">
			<article className="w-full h-full pt-6">
				<>
					{props.attendantData?.length === 0 ? (
						<div className="mt-24">
							<h1>No Attendant(s) Available</h1>
						</div>
					) : (
						<div className="w-full grid lg:grid-cols-3 md:grid-cols-2 gap-x-4 gap-y-4 mt-6">
							{props?.attendantData?.map((v) => (
								<div key={v?.id}>
									<div
										className="h-[157px] max-w-[429px] bg-white rounded-lg flex flex-row transition-all hover:border-2 hover:border-[#002E66]"
										onClick={fn(v)}>
										<div className="basis-[40%] flex items-start justify-start px-3">
											<div className="w-[90px] h-[90px] rounded-full self-center object-fit bg-[#D9D9D9] flex justify-center items-center">
												{v?.avatar?.url ? (
													<Avatar
														sx={{ height: "100%", width: "100%" }}
														src={v?.avatar?.url}
													/>
												) : (
													<h1 className="text-xl text-center font-extrabold">
														{v.firstName.charAt(0)}
														{v.lastName.charAt(0)}
													</h1>
												)}
											</div>
										</div>
										<div className="basis-[60%] flex flex-col py-10 pl-4 justify-between items-start text-start">
											<h2 className="text-[#002E66] text-[16px] font-bold">
												{v?.firstName} {v?.lastName}
											</h2>
											<p className="text-[#1E1E1E] text-[14px]">
												{v?.phoneNumber}
											</p>
											<p className="text-[#1E1E1E] text-[14px]">{v?.email}</p>
											<p className="text-[#4E8280] text-[12px]">
												{v?.meta?.salesRecord?.totalSales || 0} total sale(s)
											</p>
										</div>
									</div>
								</div>
							))}
						</div>
					)}
				</>

				{showFlagModal && (
					<Modal>
						<FlagModal
							info="Are you sure you want to flag?"
							onClose={() => setShowFlagModal(false)}
							onConfirmation={() => console.log(selected)}
						/>
					</Modal>
				)}
			</article>
		</section>
	);
}

// milk brother alpha tooth zebra cry they puzzle drip despair master under
