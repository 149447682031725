export enum API_ROUTE {
	LOGIN = "auth/system-admin/login-email",
	GET_ALL_HQ = "/system-admin/station-hq",
	ADD_NEW_HQ = "system-admin/station-hq",
	GET_ALL_BRANCH = "/system-admin/station/branches",
	ADD_NEW_BRANCH = "station/branch",
	HQ_BRANCH = "station/hq/branch",
	FETCH_ALL_TRANSACTION = "system-admin/transactions",
	FETCH_ALL_USERS = "system-admin/users",
	FETCH_ALL_USER = "system-admin/user",
	FETCH_BRANCH = "system-admin/station/branch",
	FETCH_SINGLE_HQ = "system-admin/station-hq",
	SELF_HELP = "system-admin/self-help",
	ADMIN = "system-admin",
	SAVE_WEBSITE_INFO = "system-admin/website-info",
	NOTIFICATION = "system-admin/notification",
	TICKET = "system-admin/ticket",
	FEEDS = "system-admin/feed",
	FORGOT_PASSWORD = "auth/system-admin/forgot-password",
	RESET_PASSWORD = "auth/system-admin/reset-password",
	RESEND_OTP = "auth/service-provider/resend-otp",
	DASHBOARD_INFO = "station/hq/dashboard-summary",

	ADD_HQ_BRANCH = "system-admin/station-branch",
	HQ_ADMIN_LOGIN = "auth/station/hq/login-email",
	// station/branch/646def63111438d2777c5d06
	STATION_BRANCH_CHANGE = "station/branch",
	DEPOT = "system-admin/depot",
	DEPOT_ORDER = "station/hq/depot/orders",
	DEPOT_ORDER_SINGLE = "station/hq/depot/order",
	DEPOT_INVOICE_LIST = "station/hq/depot/orders/invoices",
	DEPOT_INVOICE_SINGLE = "station/hq/depot/orders/invoice",

	//station/hq/depot/orders/invoices
}

export enum APP_ROUTE {
	// LANDING_PAGE = "/",
	DASHBOARD = "/",
	ABOUT_US = "/about",
	CONTACT_US = "/contact",
	LOGIN = "/login",
	FORGOT_PASSWORD = "/forgot-password",
	BRANCH = "manage-Branch",
	TRANSACTIONS = "transactions",
	SUPPORT = "support",
	NOTIFICATION = "notification",
	SELF_HELP = "self-help",
	SETTINGS = "settings",
	USER = "/users",
	USER_PROFILE = "/users/:name",
	USER_WALLET = "/users/:name/wallet",
	MANAGEHQ = "/manage-HQ",
	MANAGE_SINGLE_HQ = "manage-HQ/:id",
	MANAGE_SINGLE_BRANCH = "manage-branch/:id",
	BRANCHES = "/branches",
	VIEW_WALLET = "/view/wallet",
	DEPOT = "/depot",
	DEPOT_MARKET = "/market",
	ATTENDANT_PROFILE = "branch/:id/attendant",
	ATTENDANT_PROFILE_INFO = "branch/:id/attendant/:name",
	ATTENDANT_REVIEW = "branch/:id/reviews",
	VIEW_HQ_WALLET = "view/:id/wallet",
	VIEW_HQ_BRANCH = "view/:id/branch",
	ADMIN_PROFILE = "admin/profile",
	MANAGE_ADMIN = "manage/admin",
	MANAGE_WEBSITE = "manage/website",
	RESET_PASSWORD = "/reset-password",
	ADD_NEW_SELF_HELP = "/add/new/self-help",
	ADD_NEW_SELF_HELP_SINGLE = "/self-help/:name",
	FEEDS = "/feeds",
	SINGLE_ADMIN = "/settings/manage-admin/:id",
	WITHDRAWAL = "/withdrawal",
}

export enum HQ_APP_ROUTE {
	BRANCH = "/manage-branch",
	SINGLE_BRANCH = "/manage-branch/:id",
	TRANSACTIONS = "/transactions",
	DEPOT = "/depot",
	DEPOT_MARKET = "/market",
	SINGLE_DEPOT = "/depot/:name",
	SINGLE_ATTENDANTS = "/manage-attendants/:id",
	SUPPORT = "/support",
	NOTIFICATION = "/notification",
	SETTINGS = "/settings",
	WIDTHDRAWAL = "/withdrawal",
	WALLET = "/wallet",
	// DEPOT = "/depots",
	REQUEST_LIST = "/request/list",
	INVOICE_LIST = "/invoice/list",
	SINGLE_INVOICE = "/invoice/:id",
	PRODUCT_LIST = "/request/:id/product/list",
	SINGLE_MARKETPLACE_DEPOT = "/market/:name",
}

export enum HQ_API_ENPOINTS {
	BRANCH = "station/branches",
	SINGLE_BRANCH = "station/hq/branch",
	BANK_LIST = "payment/bank-list",
	FUEL_LEVEL = "station/fuel-level",
	METER_READING = "station/meter-readings",
	DEPOT = "/station/depot",
	MY_DEPOT = "station/my-depots",
	DEPOT_SUMMARY = "depot/hq-request-summary",
	DEPOT_REGISTRATION = "/station/depot",

	TRANSACTIONS = "station/transactions",
	WALLET = "station/wallet",
	HQ_WALLET = "station/hq/wallet",
	HQ_WITHDRAW = "station/hq/withdraw",

	WALLET_REQUEST = "station/branch/withdraw-request",
	WALLET_WALLET = "station/branch/wallet",
	DEPOT_PRODUCTS = "depot/products",
	NOTIFICATION = "station/notification",
	SETTINGS = "station/update-user",
	HQ_SETTINGS = "station/hq",
	HQ_ADMIN = "station/update-user",
	FORGOT_PASSWORD = "auth/station/forgot-password",
	AUTH_USER = "station/me",
	RESET_PASSWORD = "auth/station/reset-password",
	RESEND_OTP = "auth/station/resend-otp",
	HQ_DASHBOARD_SUMMARY = "/dashboard/station-user",
	DEPOT_INVOICE_SINGLE = "station/hq/depot/orders/invoice",
	DEPOT_SCHEDULE_LIST = "station/hq/depot/order-schedules",
}
export enum PERMISSION {
	HQ = "hq_admin",
	SYSTEM_ADMIN = "engineer",
}

export enum SUB_DOMAIN {
	HQ = "hq",
	SYSTEM_ADMIN = "localhost:3000",
}


export enum RTKTAG {
	MANAGE_HQ = "MANAGE_HQ",
	MANAGER_BRANCH = "MANAGE_BRANCH",
	ADMIN = "ADMIN",
	WEBSITE_INFO = "WEBSITE",
	NOTIFICATION = "NOTIFICATION",
	SELP_HELP = "SELF_HELP",
	FEEDS = "FEEDS",
	HQ_BRANCH = "BRANCH",
	DEPOT = "DEPOT",
	INVOICE = "INVOICE",
	BRANCH_WALLET = "BRANCH_WALLET",
	WITHDRAWAL_REQUEST = "WITHDRAWAL_REQUEST",
}

export enum KEYS {
	USER_INFO = "fuleap-HQ-info",
}
