import { format } from "date-fns";
import React from "react";
import { CurrencyFormatter } from "src/helpers/helperFunction";

type RequestDetailsType = {
	name: string;
	amount: number;
	date: Date;
	desc?: string;
};
export default function RequestDetails({
	name,
	amount,
	date,
	desc,
}: RequestDetailsType) {
	return (
		<div className="w-full flex justify-between items-center  py-4 px-6 h-full text-sm gap-6">
			<div className="space-y-2">
				<div>
					<h1 className="text-lightgray">Branch Name</h1>
					<p>{name}</p>
				</div>

				<div>
					<h1 className="text-lightgray">Amount</h1>
					<p>{CurrencyFormatter(amount)}</p>
				</div>
				{desc ? (
					<div>
						<h1 className="text-lightgray">Description</h1>
						<p>{desc}</p>
					</div>
				) : null}
			</div>
			<div>
				<p>{format(new Date(date), "d/MM/yyyy h:m:s a")}</p>
			</div>
		</div>
	);
}
