import { IconButton } from "@mui/material";
import React, { useMemo, useState } from "react";
import { Button } from "src/components/Button";
import { FormModal } from "src/components/ModalComp";
import { SearchInput } from "src/components/inputs";
import { CurrencyFormatter } from "src/helpers/helperFunction";
import { useAuth } from "src/hooks/useAuth";
import {
	useGetAllHQTransactionsQuery,
	useHqWalletQuery,
} from "src/hq-admin/hq-api/hqTransactionApiSlice";
import { useFetchHQBranchQuery } from "src/hq-admin/hq-api/manageHqApiSlice";
import Transaction from "../Transaction";
import { TransactionsType } from "src/helpers/alias";
import { format } from "date-fns";
import { HeadCellTypes } from "../Manage-branch";
import { LoaderContainer } from "src/components/LoaderContainer";
import { useDebounce } from "src/hooks/useDebounce";
import { SelectInput } from "src/components/SelectInput";
import { SOURCES, STATUS, TYPES, filterData } from "src/helpers/data";
import { FaEye, FaEyeSlash } from "react-icons/fa6";
import FundWalletModal from "./components/FundWalletModal";
import { WithDrawalMoal } from "./components/WithdrawalModal";

const headCells: readonly HeadCellTypes[] = [
	{
		id: "transactionDate",
		minWidth: 170,
		label: "Transaction date",
	},
	// {
	// 	id: "walletId",
	// 	minWidth: 170,
	// 	label: "Wallet ID",
	// },
	{
		id: "referenceId",
		minWidth: 170,
		label: "Reference",
	},
	{
		id: "payerName",
		minWidth: 170,
		label: "Payer Name",
	},
	{
		id: "branchName",
		minWidth: 170,
		label: "Branch Name",
	},

	{
		id: "amount",
		minWidth: 170,
		label: "Transaction Amount (N)",
	},
	// {
	// 	id: "reason",
	// 	minWidth: 170,
	// 	label: "Reason",
	// },

	{
		id: "type",
		minWidth: 170,
		label: "Type",
	},

	{
		id: "status",
		minWidth: 170,
		label: "Status",
	},
];

export default function Wallet() {
	const { user } = useAuth();
	const [showBalance, setShowBalance] = useState(false);
	const [page, setPage] = useState(1);
	const result = useHqWalletQuery({});
	const [showAddModal, setShowAddModal] = useState(false);
	const [showFundModal, setFundModal] = useState(false);
	const [filteredValue, setFilteredValue] = useState<string>("");
	const { debouncedValue } = useDebounce(filteredValue, 700);
	const [info, setInfo] = useState({
		source: "user_transfer",
	});

	const fetchAllBranchResult = useFetchHQBranchQuery({
		orderBy: "createdAt:desc",
	});
	const walletInformation = useHqWalletQuery({});

	const allTransactionsResult = useGetAllHQTransactionsQuery({
		stationHQ: user?.id,
		limit: 8,
		orderBy: "createdAt:desc",
		populate: "stationBranch,stationHQ",
		search: debouncedValue,
		page,
		...(STATUS.includes(info?.source) && { status: info.source }),
		...(SOURCES.includes(info?.source) && { source: info.source }),
		...(TYPES.includes(info?.source) && { type: info.source }),
	});

	const handleChangePage = (e: any, newPage: number) => {
		setPage(newPage);
	};

	const handledTransactionResponse = useMemo(() => {
		const transactions = allTransactionsResult?.data || [];

		return transactions?.data?.reduce(
			(acc: { [index: string]: string }[], cur: TransactionsType | any) => [
				...acc,
				{
					...cur,
					transactionDate: format(
						new Date(cur.createdAt),
						"d/MM/yyyy hh:mm:ss"
					),
					HqAccountName: cur?.stationHQ?.name,
					payerName: cur?.meta?.payerName,
					referenceId: cur?.reference,
					branchName: cur?.stationBranch?.name,
					reason: cur?.purpose,
					amount: CurrencyFormatter(Number(cur?.amount)),

					status: (
						<p
							className={`${
								cur?.status?.toString().toLowerCase() === "pending"
									? "text-yellow-500"
									: cur.status.toString().toLowerCase() === "successful"
									? "text-green-500"
									: "text-red-500"
							}`}>
							{cur?.status}
						</p>
					),

					accountName: cur?.meta?.accountName,
				},
			],
			[]
		);
	}, [allTransactionsResult]);

	const handleSelectChange = (event: { target: { value: string } }) => {
		setInfo((prev) => {
			return { source: event.target.value };
		});
	};

	const handleModal = () => setShowAddModal((prevState) => !prevState);
	const handleFundWalletModal = () => setFundModal((prevState) => !prevState);
	return (
		<LoaderContainer data={allTransactionsResult || walletInformation}>
			<section>
				<article className="min-w-screen h-full ">
					<div
						className="min-w-[45%] max-w-fit h-[17.5rem] bg-[#002E66] text-white  rounded-3xl flex flex-col  gap-y-4
                 font-bold text-lg  p-6">
						<h4 className="text-left">{user?.stationHQ?.name}</h4>
						<h4 className="text-left text-sm">{user?.stationHQ?.systemCode}</h4>

						<div className="flex items-center justify-between pr-4 my-4">
							<div>
								<h2 className="text-sm text-left">Available Balance</h2>
								<h3 className="font-bold text-2xl">
									{showBalance
										? CurrencyFormatter(result?.data?.availableBalance)
										: "*******"}
								</h3>
							</div>
							<div>
								<h2 className="text-sm text-left">Branch Balance</h2>
								<h3 className="font-bold text-2xl">
									{showBalance
										? CurrencyFormatter(result?.data?.branchesBalance)
										: "*******"}
								</h3>
							</div>
							<div>
								<IconButton
									onClick={() => {
										setShowBalance((prevState) => !prevState);
									}}>
									{showBalance ? (
										<FaEyeSlash className="text-white" />
									) : (
										<FaEye className="text-white" />
									)}
								</IconButton>
							</div>
						</div>
						<div className="flex justify-between items-center">
							<Button
								text="Withdraw"
								type="button"
								className=" font-bold text-sm text-[#002E66] w-[40%] rounded-3xl px-10 py-3 hover: bg-white flex items-center justify-center"
								onClick={handleModal}
							/>
							<Button
								text="Fund wallet"
								type="button"
								className=" font-bold text-sm text-[#002E66] rounded-3xl px-10 py-3 hover: bg-white flex items-center justify-center"
								onClick={handleFundWalletModal}
							/>
						</div>
					</div>
					<div className="w-full overflow-auto">
						<div className="flex w-full justify-between items-center my-6 h-20">
							<div className="flex w-[50%] h-11  max-w-[562px] items-center gap-2 rounded-[15px] border-2 border-[#D0D5DD] bg-[#D9D9D9] px-[18px]">
								<SearchInput
									name="branch-search"
									placeholder="Search for reference id"
									value={filteredValue}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
										const target = e.target;
										setFilteredValue(target.value);
									}}
								/>
							</div>
							<div className="w-fit flex gap-x-4 items-center  px-4 h-24 py-4 ">
								<h6>Filter By: </h6>{" "}
								<SelectInput
									tabData={filterData}
									filteredValue={info.source}
									onChange={handleSelectChange}
								/>
							</div>
						</div>

						<Transaction
							headCells={headCells}
							transactions={handledTransactionResponse}
							transactionResult={allTransactionsResult}
							handleChangePage={handleChangePage}
						/>
					</div>
				</article>
				{showFundModal ? (
					<FormModal name="Fund wallet" onClick={handleFundWalletModal}>
						<FundWalletModal
							data={walletInformation?.data}
							closeModal={handleFundWalletModal}
						/>
					</FormModal>
				) : null}

				{showAddModal ? (
					<FormModal name="Withdraw" onClick={handleModal}>
						<WithDrawalMoal closeModal={handleModal} />
					</FormModal>
				) : null}
			</section>
		</LoaderContainer>
	);
}
