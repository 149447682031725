import { useFormik } from "formik";
import { Button } from "src/components/Button";
import { PasswordInput } from "src/components/inputs";
import { UpdateHQPasswordInfoValidation } from "src/helpers/YupValidation";
import { UpdateHQPasswordType } from "src/helpers/alias";
import {
	SuccessNotification,
	handleNotification,
} from "src/helpers/helperFunction";
import { useUpdateHqAdminProfileMutation } from "src/hq-admin/hq-api/settingsApiSlice";

export const ResetPassword = (props: { close: () => void; data: any }) => {
	const [updateAdmin, addNewResult] = useUpdateHqAdminProfileMutation();

	async function addNewAdmin(values: UpdateHQPasswordType) {
		try {
			const response = await updateAdmin(values).unwrap();
			if (response) {
				props.close();
			}
			SuccessNotification(response?.status);
		} catch (error: any) {
			props.close();
			handleNotification(error);
		}
	}

	const Formik = useFormik<UpdateHQPasswordType>({
		initialValues: {
			oldPassword: "",
			newPassword: "",
			confirmPassword: "",
			id: props.data?.id,
		},
		validateOnBlur: true,
		validateOnChange: true,
		validationSchema: UpdateHQPasswordInfoValidation,
		onSubmit: (values: UpdateHQPasswordType) => {
			addNewAdmin(values);
		},
	});
	const styles =
		"h-[38px] py-6 rounded-[38px] w-full border border-gray-300 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-500 px-4 text-[14px] bg-[#D9D9D9]";
	const labelStyles =
		"block mb-[6px] text-black text-start font-normal text-[14px] text-black ml-5 my-6";

	const FormData = [
		{
			id: "oldPassword",
			name: "Old password",
			type: "text",
			styles: `${styles} ${
				Formik.errors.confirmPassword && Formik.touched.confirmPassword
					? "border-red-500"
					: "border-gray-300"
			}`,
			labelStyles: labelStyles,
			onChange: Formik.handleChange,
			value: Formik.values.oldPassword,
			onBlur: Formik.handleBlur,
			disabled: addNewResult?.isLoading,
			error: Formik.errors.oldPassword,
			touched: Formik.touched.oldPassword,
		},
		{
			id: "newPassword",
			name: "New password",
			type: "text",
			styles: `${styles} ${
				Formik.errors.newPassword && Formik.touched.newPassword
					? "border-red-500"
					: "border-gray-300"
			}`,
			labelStyles: labelStyles,
			onChange: Formik.handleChange,
			value: Formik.values.newPassword,
			onBlur: Formik.handleBlur,
			disabled: addNewResult?.isLoading,
			error: Formik.errors.newPassword,
			touched: Formik.touched.newPassword,
		},
		{
			id: "confirmPassword",
			name: "Confirm password",
			type: "text",
			styles: `${styles} ${
				Formik.errors.password && Formik.touched.confirmPassword
					? "border-red-500"
					: "border-gray-300"
			}`,
			labelStyles: labelStyles,
			onChange: () =>
				Formik.setFieldValue("confirmPassword", Formik.values.password),
			value: Formik.values.confirmPassword,
			onBlur: Formik.handleBlur,
			disabled: addNewResult?.isLoading,
			// error: Formik.errors.confirmPassword,
			// touched: Formik.touched.confirmPassword,
		},
	];

	return (
		<form
			onSubmit={Formik.handleSubmit}
			className="w-full flex flex-col justify-center items-center px-4 h-full overflow-y-auto">
			<div className="grid grid-cols-1 w-full gap-x-2 content-center pt-4">
				{FormData.slice(-3).map((_v, i) => (
					<PasswordInput
						width="w-full"
						id={_v.id}
						name={_v.name}
						type={"text"}
						styles={_v.styles}
						labelStyles={_v.labelStyles}
						onChange={_v.onChange}
						value={_v.value}
						onBlur={_v.onBlur}
						disabled={_v.disabled}
						// error={_v.error}
						// touched={_v.touched}
					/>
				))}
			</div>

			<div className="w-full">
				<Button
					text="Submit"
					disabled={addNewResult?.isLoading}
					showModal={addNewResult?.isLoading}
					className="h-[41px] mt-6 font-bold text-white rounded-[38px] w-full hover: bg-[#002E66]"
					type="submit"
				/>
			</div>
		</form>
	);
};
