import { useFormik } from "formik";
import { useCallback } from "react";
import { Button } from "src/components/Button";
import { FormInput, Label, TextArea } from "src/components/inputs";
import {
	SuccessNotification,
	handleNotification,
} from "src/helpers/helperFunction";
import { useSendHQNotificationMutation } from "src/hq-admin/hq-api/notificationApiSlice";
import * as Yup from "yup";
import CustomizedHook from "./AutoComplete";

const SendNotificationValidation = Yup.object({
	title: Yup.string().label("Title").required(),
	message: Yup.string().label("Message").required(),
	sendTo: Yup.string().label("Recipient").required(),
	notify: Yup.array(),
	attachments: Yup.array(),
});
export type addBranchSchema = Yup.InferType<typeof SendNotificationValidation>;

export const SendNotificationModal = (props: {
	name: string;
	close: () => void;
	data: any;
}) => {
	const [sendNewNotification, sendNotificationResult] =
		useSendHQNotificationMutation();

	async function SendNotificationfunt(values: addBranchSchema) {
		try {
			const response = await sendNewNotification(values).unwrap();

			SuccessNotification(response?.status);
		} catch (error: any) {
			handleNotification(error);
		}
		props.close();
	}

	const Formik = useFormik<addBranchSchema>({
		initialValues: {
			title: "",
			message: "",
			notify: [],
			sendTo: "stationBranch",
			attachments: [],
		},
		validateOnBlur: true,
		validateOnChange: true,
		validationSchema: SendNotificationValidation,
		onSubmit: (values) => {
			SendNotificationfunt(values);
		},
	});
	const styles =
		"h-[38px] py-6 rounded-[38px] w-full border border-gray-300 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-500 px-4 text-[14px] bg-[#D9D9D9]";
	const labelStyles =
		"block mb-[6px] text-black text-start font-normal text-[14px] text-black ml-5 my-6";

	const FormData = [
		// {
		// 	id: "sendTo",
		// 	name: "Recipient",
		// 	type: "text",
		// 	styles: `${styles} ${
		// 		Formik.errors.sendTo && Formik.touched.sendTo
		// 			? "border-red-500"
		// 			: "border-gray-300"
		// 	}`,
		// 	labelStyles: labelStyles,
		// 	onChange: Formik.handleChange,
		// 	value: Formik.values.sendTo,
		// 	onBlur: Formik.handleBlur,
		// 	disabled: true,
		// 	error: Formik.errors.sendTo,
		// 	touched: Formik.touched.sendTo,
		// },
		{
			id: "title",
			name: "Title",
			type: "text",
			styles: `${styles} ${
				Formik.errors.title && Formik.touched.title
					? "border-red-500"
					: "border-gray-300"
			}`,
			labelStyles: labelStyles,
			onChange: Formik.handleChange,
			value: Formik.values.title,
			onBlur: Formik.handleBlur,
			disabled: sendNotificationResult?.isLoading,
			error: Formik.errors.title,
			touched: Formik.touched.title,
		},
	];

	const onSelect = useCallback((item: any) => {
		const toAllBranches = item.reduce((acc: any, item: any) => {
			return item?.id.includes("all") ? [] : [...acc, item?.manager];
		}, []);

		// console.log(toAllBranches);
		Formik.setFieldValue("notify", toAllBranches);
	}, []);

	console.log(...props.data?.data, "testsetset");
	return (
		<form
			onSubmit={Formik.handleSubmit}
			className="w-full flex flex-col justify-center items-center px-4 h-full">
			<div className="grid grid-cols-1 w-full gap-x-2 content-center">
				<div className="mt-4">
					<Label name="Select branch" styles="pt-5" />
					<CustomizedHook
						data={[{ name: "All branches", id: "all" }, ...props.data?.data]}
						onSelect={onSelect}
					/>
				</div>
				{FormData.map((_v, i) => (
					<FormInput
						id={_v.id}
						type={_v.type}
						name={_v.name}
						value={_v.value}
						onChange={_v.onChange}
						onBlur={_v.onBlur}
						error={_v.error}
						disabled={_v.disabled}
						touched={_v.touched}
						styles={_v.styles}
						labelStyles={_v.labelStyles}
					/>
				))}
			</div>

			<TextArea
				id="message"
				name="Message"
				type="text"
				labelStyles={labelStyles}
				onChange={Formik.handleChange}
				value={Formik.values.message}
				disabled={sendNotificationResult?.isLoading}
				error={Formik.errors.message}
				touched={Formik.touched.message}
			/>

			<div className="flex items-center justify-between"></div>
			<div className="w-full">
				<Button
					text="Send"
					disabled={sendNotificationResult?.isLoading}
					showModal={sendNotificationResult?.isLoading}
					className="h-[41px] mt-6 font-bold text-white rounded-[38px] w-full hover: bg-[#002E66]"
					type="submit"
				/>
			</div>
		</form>
	);
};
