import React, { Fragment, useMemo, useState } from "react";

import SummaryCard from "src/hq-admin/hq-pages/Depot/components/SummaryCard";
import { useLocation } from "react-router-dom";
import { CurrencyFormatter } from "src/helpers/helperFunction";
import Transaction from "src/hq-admin/hq-pages/Transaction";
import { useGetAllHQTransactionsQuery } from "src/hq-admin/hq-api/hqTransactionApiSlice";
import { format } from "date-fns";
import { GiCoins } from "react-icons/gi";
import { FaSortAmountUpAlt } from "react-icons/fa";
import { SelectInput } from "src/components/SelectInput";
import { SOURCES, STATUS, TYPES, filterData } from "src/helpers/data";

const headCells: any[] = [
	{
		id: "referenceId",
		minWidth: 170,
		label: "Reference",
	},
	{
		id: "productName",
		minWidth: 170,
		label: "ProductName",
	},
	{
		id: "quantity",
		minWidth: 170,
		label: "Quantity",
	},
	{
		id: "amount",
		minWidth: 170,
		label: "Transaction Amount (N)",
	},
	{
		id: "purpose",
		minWidth: 170,
		label: "Purpose",
	},
	{
		id: "transactionType",
		minWidth: 170,
		label: "Type",
	},
	{
		id: "date",
		minWidth: 170,
		label: "Transaction date",
	},
	{
		id: "status",
		minWidth: 170,
		label: "Status",
	},
];

export default function SingleAttendentsProfile() {
	const paramStateData = useLocation();
	const [info, setInfo] = useState({
		source: "user_transfer",
	});

	const attendentsProfileData = paramStateData?.state;

	const allTransactionsResult = useGetAllHQTransactionsQuery({
		pumpAttendant: attendentsProfileData?.id,
		limit: 8,
		orderBy: "createdAt:desc",
		...(STATUS.includes(info?.source) && { status: info.source }),
		...(SOURCES.includes(info?.source) && { source: info.source }),
		...(TYPES.includes(info?.source) && { type: info.source }),
	});

	const handledAPIResponse = useMemo(() => {
		const transactions = allTransactionsResult?.data?.data || [];

		return transactions?.reduce(
			(acc: { [index: string]: string }[], cur: any | any) => {
				const { amount, id, meta, ...rest } = cur;
				return [
					...acc,

					{
						...rest,
						...meta,
						date: format(new Date(cur.createdAt), "d/MM/yyyy hh:mm:ss"),
						referenceId: cur?.reference,
						doneby: cur?.meta?.from,
						walletId: cur?.meta?.walletNumber,
						productName: cur?.meta?.productName,
						quantity: cur?.meta?.quanity || 0,
						transactionType: cur?.meta?.transactionType,
						purpose: cur?.purpose,
						pumpAttendant:
							attendentsProfileData?.firstName +
							" " +
							attendentsProfileData?.lastName,

						amount: CurrencyFormatter(Number(cur?.amount)),
						name: cur?.stationBranch?.name,

						status: (
							<p
								className={`${
									cur?.status?.toString().toLowerCase() === "pending"
										? "text-yellow-500"
										: cur.status.toString().toLowerCase() === "successful"
										? "text-green-500"
										: "text-red-500"
								}`}>
								{cur?.status}
							</p>
						),
					},
				];
			},
			[]
		);
	}, [allTransactionsResult]);

	// HANDLE CHANGE FOR TABLE TAB
	const handleSelectChange = (event: { target: { value: string } }) => {
		setInfo((prev) => {
			return { ...prev, source: event.target.value };
		});
	};

	return (
		<section>
			<article className="w-full h-screen px-2">
				<>
					<div className="grid grid-cols-3 gap-3 w-full">
						<SummaryCard
							icon={<GiCoins className="h-12 w-12 mr-4 text-green-300" />}
							name="Total Sales"
							amount={attendentsProfileData?.meta?.salesRecord?.totalSales || 0}
						/>
						<SummaryCard
							icon={
								<FaSortAmountUpAlt className="h-12 w-12 mr-4 text-green-300" />
							}
							name="Total Sales Amount"
							amount={CurrencyFormatter(
								attendentsProfileData?.meta?.salesRecord?.totalSalesAmount
							)}
						/>
						<SummaryCard
							icon={<GiCoins className="h-12 w-12 mr-4 text-green-300" />}
							name="Offline Sales"
							amount={
								attendentsProfileData?.meta?.salesRecord?.offlineSales || 0
							}
						/>
						<SummaryCard
							icon={
								<FaSortAmountUpAlt className="h-12 w-12 mr-4 text-green-300" />
							}
							name="Offline Sales Amount"
							amount={CurrencyFormatter(
								attendentsProfileData?.meta?.salesRecord?.offlineSalesAmount
							)}
						/>

						<SummaryCard
							icon={<GiCoins className="h-12 w-12 mr-4 text-green-300" />}
							name="Online Sales"
							amount={
								attendentsProfileData?.meta?.salesRecord?.onlineSales || 0
							}
						/>
						<SummaryCard
							icon={
								<FaSortAmountUpAlt className="h-12 w-12 mr-4 text-green-300" />
							}
							name="Online Sales Amount"
							amount={CurrencyFormatter(
								attendentsProfileData?.meta?.salesRecord?.onlineSalesAmount
							)}
						/>
					</div>
				</>

				<div className="w-fit mr-auto flex items-center gap-x-4 px-4 h-24 py-4 mt-4">
					<h6>Filter By: </h6>{" "}
					<SelectInput
						tabData={filterData}
						filteredValue={info.source}
						onChange={handleSelectChange}
					/>
				</div>

				<Transaction
					headCells={headCells}
					transactions={handledAPIResponse}
					transactionResult={allTransactionsResult}
				/>
			</article>
		</section>
	);
}
