import React, { forwardRef } from "react";
import Logo from "src/assets/img/Logo.jpeg";
import {
	CurrencyFormatter,
	splitByUpperCase,
} from "src/helpers/helperFunction";

const DownloadableReceipt = React.forwardRef((props: any, ref) => {
	const downloadedData = {
		transactionDate: props?.data?.transactionDate,
		reference: props?.data?.reference,
		amount: props?.data?.amount,
		fee: props?.data?.fee,
		...(props?.data?.branchName && { branch: props?.data?.branchName }),
		...(props?.data?.accountName && { branch: props?.data?.accountName }),
		...(props?.data?.stationHQ && { branch: props?.data?.stationHQ }),

		payerName: props?.data?.payerName,
		walletNumber: props?.data?.walletNumber,
		reason: props?.data?.message,
		category: props?.data?.category.replace("_", " "),
		status: props?.data?.status,
	};
	const headers = Object.keys(downloadedData || {});
	const content = Object.values(downloadedData || {}) as any;

	return (
		<div
			ref={ref as any}
			className="max-w-2xl mx-auto bg-white h-full  rounded-lg  p-6">
			<div className="text-center mb-6">
				<img src={Logo} alt="Logo" className="mx-auto h-48 w-48 mb-2" />
				<h2 className="text-xl font-bold text-grey-800 dark:text-grey-200">
					Aptfuel Transaction Receipt
				</h2>
			</div>
			{headers?.map((item, i) => (
				<div
					key={i + 1}
					className="grid grid-cols-2 border-b border-[#e3e3e3]  mx-auto gap-6 mb-4 ">
					<p className="text-sm text-start capitalize text-grey-600 dark:text-grey-400">
						{splitByUpperCase(item)}
					</p>
					<p className=" text-grey-800 dark:text-grey-200 text-right capitalize">
						{content[i]}
					</p>
				</div>
			))}
			<div className="text-center mb-6 flex flex-col items-center justify-end h-56 ">
				<p className="font-bold">Total Amount</p>
				<div className="inline-block bg-primary text-white py-2 px-4 rounded-lg mt-2 mb-4">
					<p className="font-bold">{downloadedData?.amount}</p>
				</div>
				<p className="text-xs text-grey-600 dark:text-grey-400 text-center">
					Disclaimer: If Your Transaction Is Successful And The Beneficiary's
					Account Will Be Credited. Please Note That All Transactions Are
					Verified And Are Subject To Be Interrupted Due To The Nature Of The
					Internet Such As Delayed Transmission, Transmission Blackout And
					Incorrect Data Transmission.
				</p>
			</div>
		</div>
	);
});

export default DownloadableReceipt;
