import React, { Fragment, useMemo, useState } from "react";
import { cardBtnType } from "src/helpers/alias";
import AdminProfile from "src/assets/img/AdminProfile.svg";
import ResetPasword from "src/assets/img/ResetPasword.svg";
import { CardButton } from "src/components/Card";
import ProfileCard from "src/components/ProfileCard";

import { FormModal } from "src/components/ModalComp";
import { useGetHQDetailsQuery } from "src/hq-admin/hq-api/hqAuthSlice";
import { LoaderContainer } from "src/components/LoaderContainer";
import { ResetAdminInfo } from "./ResetAdminInfo";
import { ResetPassword } from "./ResetPassword";
import { ResetHQInfo } from "./ResetHQAdmin";

export default function HqSetting() {
	const hqDetailsResult = useGetHQDetailsQuery({});
	const user = useMemo(() => hqDetailsResult?.data?.data, [hqDetailsResult]);

	const [cardName, setName] = useState<string>("HQ Details");
	const [showResetModal, setShowResetModal] = useState(false);

	const HQData: cardBtnType[] = [
		{
			id: 0,
			icon: AdminProfile,
			name: "HQ Details",
		},
		{
			id: 1,
			icon: AdminProfile,
			name: "Profile",
		},
		{
			id: 3,
			icon: ResetPasword,
			name: "Update Profile",
		},
		{
			id: 4,
			icon: ResetPasword,
			name: "Update Password",
		},
	];

	function handleCloseResetModal() {
		setShowResetModal((prevState) => !prevState);
	}

	const handledAPIResponse = useMemo(() => {
		return {
			profile: {
				firstName: user?.firstName,
				lastName: user?.lastName,
				email: user?.email,
				role: user?.role,
				phoneNumber: user?.phoneNumber,
				systemCode: user?.systemCode,
			},
			station: {
				email: user?.stationHQ?.email,
				name: user?.stationHQ?.name,
				phoneNumber: user?.stationHQ?.phoneNumber,
				address: user?.stationHQ?.hqAddress,
				systemCode: user?.stationHQ?.systemCode,
			},
			avatar: user?.stationHQ?.logo?.url,
			id: user?.stationHQ?.id,
		};
	}, [user]);

	return (
		<section>
			<LoaderContainer data={hqDetailsResult}>
				<article>
					<div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4  py-3">
						<>
							{HQData.map((dt) => (
								<Fragment>
									<CardButton
										name={dt.name}
										icon={dt.icon}
										link={dt.link}
										activeBtn={cardName}
										height={"98px"}
										onClick={() => setName(dt.name)}
									/>
								</Fragment>
							))}
						</>
					</div>

					{cardName.toLowerCase().includes("hq") ? (
						<ProfileCard
							showBanner={true}
							data={handledAPIResponse?.station || {}}
							showImage={false}
							logoBranch="HQ"
							imageURL={handledAPIResponse.avatar}
							showButton={true}
							onClick={handleCloseResetModal}
						/>
					) : null}
					{cardName.toLowerCase() === "profile" ? (
						<ProfileCard
							showBanner={false}
							data={handledAPIResponse?.profile || {}}
							showImage={false}
							// imageURL={handledAPIResponse.avatar}
						/>
					) : null}
					{cardName.toLowerCase() === "update profile" ? (
						<FormModal
							name="Update Settings"
							onClick={() => setName("profile")}>
							<ResetAdminInfo
								data={handledAPIResponse}
								close={() => setName("profile")}
							/>
						</FormModal>
					) : null}
					{cardName.toLowerCase() === "update password" ? (
						<FormModal
							name="Update Password"
							onClick={() => setName("profile")}>
							<ResetPassword
								data={handledAPIResponse}
								close={() => setName("profile")}
							/>
						</FormModal>
					) : null}
				</article>
			</LoaderContainer>
			{showResetModal ? (
				<FormModal name="Update Hq details" onClick={handleCloseResetModal}>
					<ResetHQInfo
						close={handleCloseResetModal}
						data={handledAPIResponse}
					/>
				</FormModal>
			) : null}
		</section>
	);
}
